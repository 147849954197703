import router from "../../router";
const url = "/Pagos/pagos";
const initialState = () => ({
  collection: undefined,
  resource: {},
  loader: false,
  lote: {},
  pagos: [],
  transportista: false,
  corte: false
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_RESOURCE(state, payload) {
      state.resource = payload;
    },
    SET_LOADER(state, payload) {
      state.loader = payload;
    },
    setLote(state, payload = {}) {
      state.lote = payload;
    },
    setPagos(state, payload = []) {
      state.pagos = payload;
    },
    setTransportista(state, payload = false) {
      state.transportista = payload;
    },
    setCorte(state, payload = false) {
      state.corte = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch }) {
      commit("SET_LOADER", true);
      commit("SET_COLLECTION", []);
      window.axios
        .get(url)
        .then(resp => {
          commit("SET_COLLECTION", resp.data.collection);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async getPagosLote({ dispatch, state, commit }) {
      commit("SET_LOADER", true);
      await window.axios
        .get(`${url}/lote/${state.lote.id}`)
        .then(resp => {
          commit("setPagos", resp.data.collection);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async storePago({ commit, dispatch }, data) {
      commit("setErrors", [], { root: true });
      commit("SET_LOADER", true);
      await window.axios
        .post(url, data)
        .then(resp => {
          commit("SET_COLLECTION", resp.data.collection);
          commit("setPagos", resp.data.pagos);
          dispatch("Calculadora/buscar", null, { root: true });
          window.swal(resp.data.message, { icon: "success", timer: 1500 });
          commit("setTransportista");
          commit("setCorte");
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async deletePago({ commit, dispatch }, id) {
      commit("SET_LOADER", true);
      window.axios
        .delete(`${url}/${id}`)
        .then(resp => {
          commit("SET_COLLECTION", resp.data.collection);
          window.swal(resp.data.message, { icon: "success", timer: 1500 });
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    }
  },
  getters: {
    getNuevo: state => tipo => {
      let obj = {};
      obj.lote_id = state.lote.id;
      obj.beneficiario = state.lote.productor;
      obj.tipo = tipo;
      obj.fecha = new Date().toISOString().split("T")[0];
      return obj;
      //return state.todos.filter(todo => todo.done);
    }
  }
};

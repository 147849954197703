<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-form @submit.prevent="sendTarifa">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              dense
              outlined
              label="Nombre del Transportista"
              v-model="tarifa.nombre"
              :error-messages="errors.nombre"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              dense
              outlined
              label="Importe"
              type="number"
              step="0.01"
              v-model.number="tarifa.importe"
              :error-messages="errors.importe"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              dense
              outlined
              label="IVA"
              type="number"
              step="0.01"
              v-model.number="tarifa.iva"
              :error-messages="errors.iva"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              dense
              outlined
              label="Retención IVA"
              type="number"
              step="0.01"
              v-model.number="tarifa.retencion"
              :error-messages="errors.retencion"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              dense
              outlined
              label="Total"
              type="number"
              step="0.01"
              v-model.number="tarifa.total"
              :error-messages="errors.total"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              rows="3"
              outlined
              label="Municipios"
              v-model="tarifa.municipios"
              :error-messages="errors.municipios"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" @click="cancelar" :disabled="loader">
          cancelar
        </v-btn>
        <v-btn
          color="success"
          type="submit"
          :disabled="loader"
          v-if="modificado"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Form",
  data: () => ({
    original: undefined
  }),
  computed: {
    ...mapState({
      tarifa: state => state.Tarifas.resource,
      loader: state => state.Tarifas.loader,
      errors: state => state.errors
    }),
    modificado() {
      return JSON.stringify(this.tarifa) !== JSON.stringify(this.original);
    }
  },
  mounted() {
    if (!this.tarifa) this.$router.push({ name: "tarifas" }).catch(() => {});
    this.original = Object.assign({}, this.tarifa);
  },
  methods: {
    cancelar() {
      this.$router.go(-1);
    },
    ...mapActions("Tarifas", ["sendTarifa"])
  }
};
</script>

import router from "../../router";
const url = "/Pagos/comisionistas";
const initialState = () => ({
  collection: undefined,
  resource: undefined,
  loader: false,
  historial: undefined
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_RESOURCE(state, payload) {
      state.resource = payload;
    },
    SET_LOADER(state, payload) {
      state.loader = payload;
    },
    SET_HISTORIAL(state, payload) {
      state.historial = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch }) {
      commit("SET_COLLECTION", []);
      commit("SET_LOADER", true);
      window.axios
        .get(url)
        .then(resp => {
          commit("SET_COLLECTION", resp.data.collection);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    nuevo({ commit }) {
      commit("SET_RESOURCE", {});
      router.push({ name: "comisionistas.create" });
    },
    editar({ commit }, item) {
      commit("SET_RESOURCE", item);
      let id = item.id,
        nombre = `Editando a ${item.nombre}`;
      router.push({ name: "comisionistas.edit", params: { id, nombre } });
    },
    async guardar({ commit, dispatch, state }) {
      let item = state.resource;
      commit("SET_LOADER", true);
      commit("setErrors", [], { root: true });
      if (!item.id) {
        await window.axios
          .post(url, item)
          .then(resp => {
            window.swal(resp.data.message, { icon: "success", timer: 1500 });
            commit("SET_COLLECTION", resp.data.collection);
            router.push({ name: "comisionistas" }).catch(() => {});
          })
          .catch(e => dispatch("errores", e, { root: true }))
          .finally(() => commit("SET_LOADER", false));
      } else {
        await window.axios
          .put(`${url}/${item.id}`, item)
          .then(resp => {
            window.swal(resp.data.message, { icon: "success", timer: 1500 });
            commit("SET_COLLECTION", resp.data.collection);
            router.push({ name: "comisionistas" }).catch(() => {});
          })
          .catch(e => dispatch("errores", e, { root: true }))
          .finally(() => commit("SET_LOADER", false));
      }
    },
    async deshabilitar({ commit, dispatch }, id) {
      commit("SET_LOADER", true);
      await window.axios
        .delete(`${url}/${id}`)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1500 });
          commit("SET_COLLECTION", resp.data.collection);
          router.push({ name: "comisionistas" }).catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async habilitar({ commit, dispatch }, id) {
      commit("SET_LOADER", true);
      await window.axios
        .post(`${url}/habilitar/${id}`)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1500 });
          commit("SET_COLLECTION", resp.data.collection);
          router.push({ name: "comisionistas" }).catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async historial({ commit, dispatch }, item) {
      commit("SET_LOADER", true);
      let id = item.id,
        nombre = `Historial de ${item.nombre}`;
      commit("SET_RESOURCE", item);
      await window.axios
        .get(`${url}/historial/${id}`)
        .then(resp => {
          commit("SET_HISTORIAL", resp.data);
          router.push({
            name: "comisionistas.historial",
            params: { id, nombre }
          });
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    }
  }
};

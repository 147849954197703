<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="blue lighten-4">
      Producto Seleccionado
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="mt-3">
        <v-col cols="12" md="3">
          <v-autocomplete
            dense
            outlined
            small-chips
            clearable
            label="Forma de Pago al Productor"
            :items="formas"
            v-model="forma"
          />
        </v-col>
        <v-col cols="12" md="2" v-if="forma == 'KILOS'">
          <v-text-field
            dense
            outlined
            label="Precio por kilo"
            type="number"
            step="0.01"
            v-model.number="nuevoPrecio"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-btn
            class="mr-3"
            color="success"
            @click="colocarPrecios"
            v-if="sugerir"
            >ultimos precios</v-btn
          >
          <template v-if="loteModificado || detallesModificados">
            <v-btn color="primary" @click="limpiar" class="mr-3"
              >resetear</v-btn
            >
            <v-btn color="success" @click="guardar" v-if="forma && conPrecio"
              >Guardar</v-btn
            >
          </template>
        </v-col>
        <v-col cols="12" md="4" offset-md="8">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :items-per-page="items.length"
        :headers="headers"
        :items="items"
        class="elevation-1"
        :search.sync="search"
        mobile-breakpoint="0"
        hide-default-footer
      >
        <template v-slot:[`item.precio`]="{ item }">
          <template v-if="lote.forma_pago_productor === 'BANDA'">
            <v-text-field
              dense
              type="number"
              step="0.01"
              min="0"
              class="input-numerico"
              v-model.number="item.precio"
              v-if="!item.producto.includes('MERMA')"
            />
          </template>
          <template v-else>
            {{ item.precio | moneda }}
          </template>
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ (item.precio * item.kilos) | moneda }}
        </template>
        <template v-slot:[`item.kilos`]="{ item }">
          {{ item.kilos | decimal }}
        </template>
      </v-data-table>
      <v-row class="mt-3">
        <v-col> Importe a Pagar: {{ importeTotal | moneda }} </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Producto",
  data: () => ({
    headers: [
      { text: "Producto", value: "producto" },
      { text: "Cantidad", value: "cantidad", align: "end" },
      { text: "Kilos", value: "kilos", align: "end" },
      { text: "Precio", value: "precio", align: "end" },
      { text: "Importe", value: "importe", align: "end" }
    ],
    search: undefined,
    loteOriginal: undefined,
    detalles: undefined,
    forma: undefined,
    nuevoPrecio: undefined
  }),
  computed: {
    ...mapState({
      items: state => state.Lotes.resource.details,
      lote: state => state.Lotes.resource.lote,
      formas: state => state.Lotes.resource.formas_pago,
      pago_por_kilo: state => state.Lotes.resource.pago_por_kilo
    }),
    importeTotal() {
      let importe = 0;
      let productos = this.items.filter(x => {
        return !x.producto.includes("MERMA") && x.precio;
      });
      productos.forEach(x => {
        importe += parseFloat(x.precio) * parseFloat(x.kilos);
      });
      return importe;
    },
    fijo() {
      let precio = this.pago_por_kilo.fijo[this.lote.tipo_aguacate][
        this.lote.tipo_corte.split(" ")[0]
      ];
      precio = parseFloat(precio);
      if (isNaN(precio)) return 0;
      return precio;
    },
    sugerir() {
      let conPrecio = this.items.filter(x => x.precio);
      if (conPrecio.length == 0 && this.lote.forma_pago_productor == "BANDA")
        return true;
      return false;
    },
    loteModificado() {
      return JSON.stringify(this.lote) !== JSON.stringify(this.loteOriginal);
    },
    detallesModificados() {
      if (
        this.forma == "KILOS" &&
        JSON.stringify(this.items) !== JSON.stringify(this.detalles)
      ) {
        return true;
      }
      if (
        this.forma == "KILOS" &&
        this.loteOriginal.forma_pago_productor == "KILOS"
      ) {
        return false;
      }

      return JSON.stringify(this.items) !== JSON.stringify(this.detalles);
    },
    conPrecio() {
      let listos = this.items.filter(x => x.precio);
      return listos.length > 0;
    },
    kilosDetalles(){
      return window._.sumBy(this.items,'kilos')
    }
  },
  mounted() {
    this.loteOriginal = Object.assign({}, this.lote);
    this.detalles = JSON.parse(JSON.stringify(this.items));
    this.forma = this.loteOriginal.forma_pago_productor;
    if (this.loteOriginal.forma_pago_productor == "KILOS") {
      let primero = this.items[0];
      this.nuevoPrecio = primero.precio;
    }
  },
  methods: {
    itemEditado(item) {
      let buscado = this.detalles.find(x => x.id == item.id);
      return item.precio !== buscado.precio;
    },
    colocarPrecios() {
      this.items.forEach(x => {
        x.precio = x.ultimo_precio;
      });
    },
    limpiar() {
      this.forma = this.loteOriginal.forma_pago_productor;
      this.detalles = JSON.parse(JSON.stringify(this.items));
      if (this.loteOriginal.forma_pago_productor == "KILOS") {
        let primero = this.items[0];
        this.nuevoPrecio = primero.precio;
      }
    },
    guardar() {
      let precio = this.fijo;
      if (this.nuevoPrecio != this.fijo) {
        precio = this.nuevoPrecio;
      }
      let test = {
        forma: this.forma,
        precio,
        items: this.items.filter(x => !x.producto.includes("MERMA"))
      };
      this.updateLote(test);
    },
    ...mapActions("Lotes", ["updateLote"])
  },
  watch: {
    forma: function(val) {
      if (!val) {
        this.items.forEach(x => {
          x.precio = null;
        });
      }
      this.lote.forma_pago_productor = val;
      if (val == "KILOS" && this.loteOriginal.forma_pago_productor !== "KILOS") {
        this.items.forEach(x => {
          if (!x.producto.includes("MERMA")) {
            if (x.cantidad > 0) x.precio = this.fijo;
          } else {
            x.precio = 0;
          }
        });
      }
      if (this.loteOriginal.forma_pago_productor == "KILOS" && val == "BANDA") {
        this.items.forEach(x => {
          x.precio = null;
        });
      }
    },
    lote: function(val) {
      this.loteOriginal = Object.assign({}, val);
      this.detalles = JSON.parse(JSON.stringify(this.items));
      this.forma = this.loteOriginal.forma_pago_productor;
    },
    nuevoPrecio: function(val) {
      let primero = this.detalles[0];
      if (val != primero.precio) {
        this.items.forEach(x => {
          if (!x.producto.includes("MERMA")) {
            if (x.cantidad > 0) x.precio = val;
          } else {
            x.precio = 0;
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-input.input-numerico {
  text-align: end !important;
  font-size: 14px !important;
  margin-top: 10px !important;
}
.v-input.input-numerico
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > input {
  text-align: end !important;
}
</style>

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="orange lighten-4">
      Datos del Lote
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="mt-3">
        <v-col> N° de Lote: {{ lote.lote|folio }} </v-col>
        <v-col> N° Seleccion: {{ lote.seleccion|folio }} </v-col>
        <v-col> Tipo de corte: {{ lote.tipo_corte }} </v-col>
      </v-row>
      <v-row>
        <v-col> Fecha de corte: {{ lote.fecha_corte | date }} </v-col>
        <v-col> Fecha de Corrida {{ lote.fecha | date }} </v-col>
        <v-col> Peso Neto: {{ lote.peso_neto | decimal }}</v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Lote",
  computed: {
    ...mapState({
      lote: state => state.Lotes.resource.lote
    })
  }
};
</script>

<!-- eslint-disable -->
<template>
  <v-card height="100%" v-if="comisionista">
    <v-form @submit.prevent="guardar">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              dense
              outlined
              label="Nombre"
              v-model="comisionista.nombre"
              :error-messages="errors.nombre"
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              dense
              outlined
              label="Correo Electrónico"
              type="email"
              v-model="comisionista.email"
              :error-messages="errors.email"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              dense
              outlined
              label="Teléfono"
              v-model="comisionista.telefono"
              :error-messages="errors.telefono"
            />
          </v-col>
          <v-col>
            <v-textarea
              outlined
              label="Domicilio"
              rows="4"
              v-model="comisionista.domicilio"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" @click="cancelar" :disabled="loader">
          cancelar
        </v-btn>
        <v-btn
          color="success"
          type="submit"
          :disabled="loader"
          v-if="modificado"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Form",
  data: () => ({
    original: undefined
  }),
  computed: {
    ...mapState({
      comisionista: state => state.Comisionistas.resource,
      loader: state => state.Comisionistas.loader,
      errors: state => state.errors
    }),
    modificado() {
      return (
        JSON.stringify(this.comisionista) !== JSON.stringify(this.original)
      );
    }
  },
  mounted() {
    this.$store.commit("setErrors", []);
    if (!this.comisionista) this.$router.push({ name: "comisionistas" });
    this.original = Object.assign({}, this.comisionista);
  },
  methods: {
    ...mapActions("Comisionistas", ["guardar"]),
    cancelar() {
      this.$router.push({ name: "comisionistas" });
    }
  }
};
</script>

//component: () => import(/* webpackChunkName: "about" */ "@/views/About.vue");
import Home from "../views/Home.vue";
import logout from "./../components/base/logout.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      name: "Dashboard"
    }
  },
  {
    path: "/Inicio",
    name: "inicio",
    component: Home,
    meta: {
      name: "Dashboard"
    }
  },
  {
    path: "/Salir",
    name: "logout",
    component: logout
  }
];

export default routes;

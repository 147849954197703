<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text v-if="comisionista">
      <v-expansion-panels multiple>
        <Lotes />
        <Pagos />
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="regresar">regresar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import Lotes from "./PanelLotes";
import Pagos from "./PanelPagos";
export default {
  name: "Historial",
  components: { Lotes, Pagos },
  computed: {
    ...mapState({
      comisionista: state => state.Comisionistas.resource
    })
  },
  mounted() {
    if (!this.comisionista) this.regresar();
  },
  methods: {
    regresar() {
      this.$router.push({ name: "comisionistas" }).catch(() => {});
    }
  }
};
</script>

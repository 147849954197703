<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-form @submit.prevent="buscarLocal">
        <v-row>
          <v-col cols="12" md="4" v-if="cargado">
            <v-select
              dense
              outlined
              clearable
              small-chips
              :items="tipos"
              v-model="tipo"
              label="Tipo de Pago"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              dense
              outlined
              label="Fecha Inicial"
              v-model="fechas.inicio"
              type="date"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              dense
              outlined
              label="Fecha Final"
              v-model="fechas.fin"
              type="date"
            />
          </v-col>
          <v-col>
            <v-btn color="primary" type="submit" :disabled="loader">
              buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <Comisionista v-if="tipo == 'COMISIONISTA'" />
      <Cortes v-if="tipo == 'CORTES'" />
      <Productor v-if="tipo == 'PRODUCTOR'" />
      <Transportista v-if="tipo == 'TRANSPORTISTA'" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Comisionista from "./Comisionista";
import Cortes from "./Cortes";
import Productor from "./Productor";
import Transportista from "./Transportista";
export default {
  name: "Nuevo",
  components: {
    Comisionista,
    Cortes,
    Productor,
    Transportista
  },
  data: () => ({
    tipo: undefined
  }),
  computed: {
    ...mapState({
      fechas: state => state.Calculadora.fechas,
      loader: state => state.Calculadora.loader,
      lotes: state => state.Calculadora.lotes,
      cortes: state => state.Calculadora.cortes
    }),
    cargado() {
      if (!this.cortes || !this.lotes) return false;
      return this.cortes.length || this.lotes.length ? true : false;
    },
    tipos() {
      const set = new Set();
      if (this.lotes.length) {
        set.add("PRODUCTOR");
      }
      if (this.cortes.length) {
        set.add("TRANSPORTISTA");
        set.add("CORTES");
      }
      return [...set].sort();
    }
  },
  mounted() {
    this.$store.commit("Calculadora/SET_INITIAL_STATE");
  },
  methods: {
    ...mapActions("Calculadora", ["buscar"]),
    buscarLocal() {
      this.tipo = undefined;
      this.buscar();
    }
  }
};
</script>

<!-- eslint-disable -->
<template>
  <v-card>
    <v-card-title primary-title class="mt-1">
      Pagos al productor {{ lote.productor }} Lote: {{ lote.lote | folio }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col> Huerta: {{ lote.huerta }} </v-col>
        <v-col> Fecha de Corte: {{ lote.fecha_corte | date }} </v-col>
        <v-col> Fecha de Proceso: {{ lote.fecha | date }} </v-col>
        <v-col>Importe a Pagar: {{ lote.pago_real_productor | moneda }}</v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-form @submit.prevent="sendPago">
        <v-row class="mt-2">
          <v-col cols="12" md="2">
            <v-text-field
              dense
              outlined
              type="date"
              label="Fecha del Pago"
              v-model="pago.fecha"
              :error-messages="errors.fecha"
              :max="today"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              dense
              outlined
              label="Factura"
              v-model="pago.factura"
              :error-messages="errors.factura"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              dense
              outlined
              label="Beneficiario"
              v-model="pago.beneficiario"
              :error-messages="errors.beneficiario"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2">
            <v-autocomplete
              dense
              outlined
              label="Forma de Pago"
              :items="formas"
              v-model="pago.forma_pago"
              :error-messages="errors.forma_pago"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              dense
              outlined
              type="number"
              step="0.01"
              label="Importe Pagado"
              v-model="pago.importe"
              :error-messages="errors.importe"
              :max="lote.resta_productor"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
            v-if="pago.forma_pago !== 'Efectivo' && pago.forma_pago"
          >
            <v-text-field
              dense
              outlined
              label="Referencia"
              v-model="pago.referencia"
              :error-messages="errors.referencia"
            />
          </v-col>
          <v-col>
            <v-btn color="success" type="submit" :disabled="loader">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-text v-if="items.length">
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        class="elevation-1"
        mobile-breakpoint="0"
      >
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | folio }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="$store.commit('Pagos/setLote')">
        regresar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "PagosProductor",
  computed: {
    ...mapState({
      lote: state => state.Pagos.lote,
      loader: state => state.Pagos.loader,
      pagos: state => state.Pagos.pagos,
      errors: state => state.errors
    }),
    items() {
      if (!this.pagos.length) return [];
      return this.pagos.filter(x => x.tipo === "PRODUCTOR");
    },
    formas() {
      let array = ["Efectivo", "Cheque", "Transferencia"];
      return array.sort();
    },
    today() {
      return new Date().toISOString().split("T")[0];
    }
  },
  data: () => ({
    pago: { tipo: "Productor" },
    headers: [
      { text: "Folio", value: "id" },
      { text: "Fecha", value: "fecha" },
      { text: "Factura", value: "factura" },
      { text: "Forma de Pago", value: "forma_pago" },
      { text: "Referencia", value: "referencia" },
      { text: "Beneficiario", value: "beneficiario" },
      { text: "Importe", value: "importe", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ]
  }),
  beforeMount() {
    //this.pago.tipo = "Productor";
    this.$store.commit("Pagos/setPagos");
    this.$store.commit("setErrors");
  },
  mounted() {
    if (this.lote.pagos_productor > 0) this.getPagosLote();
  },
  methods: {
    ...mapActions("Pagos", ["getPagosLote", "storePago"]),
    sendPago() {
      this.storePago(this.pago);
    }
  },
  watch: {
    pagos: function() {
      this.pago = this.$store.getters["Pagos/getNuevo"]("Productor");
    }
  }
};
</script>

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="blue lighten-4">
      Lotes
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" md="4">
          <v-btn small color="info" class="mt-5 ml-3" @click="actualizar">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers"
        class="elevation-1"
        :search.sync="search"
        :items="items"
      >
        <template v-slot:[`item.lote`]="{ item }">
          {{ item.lote | folio }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
        <template v-slot:[`item.fecha_corte`]="{ item }">
          {{ item.fecha_corte | date }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="primary" @click="detalle(item)">
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Lotes",
  data: () => ({
    headers: [
      { text: "Lote", value: "lote" },
      { text: "Fecha Corte", value: "fecha_corte" },
      { text: "Fecha Proceso", value: "fecha" },
      { text: "Tipo de Corte", value: "tipo_corte" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    search: undefined
  }),
  computed: {
    ...mapState({
      historial: state => state.Huertas.historial
    }),
    items() {
      return this.historial ? this.historial.lotes : [];
    }
  },
  methods: {
    ...mapActions("Huertas", ["historialHuerta"]),
    actualizar() {
      this.historialHuerta(this.$route.params.id);
    },
    detalle(item) {
      this.$store.dispatch("Lotes/getLote", item.id);
    }
  }
};
</script>

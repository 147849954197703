import router from "../../router";
const initialState = () => ({
  login: false,
  user: {}
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_LOGIN(state, payload) {
      state.login = payload;
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    }
  },
  actions: {
    async login({ commit, state, dispatch }) {
      commit("setErrors", [], { root: true });
      let user = state.user;
      user.tipo = "Pagos";
      await window.axios
        .post("auth/login", user)
        .then(resp => {
          let payload = resp.data;
          window.axios.defaults.headers.common.Authorization =
            "Bearer " + payload.access_token; // Very important to have "Bearer "
          localStorage.setItem("token", payload.access_token);
          commit("SET_LOGIN", true);
          dispatch("checkUser");
          commit("setErrors", [], { root: true });
          router.push({ name: "inicio" }).catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }));
    },
    async checkUser({ commit, dispatch }) {
      commit("setErrors", [], { root: true });
      if (!localStorage.token) {
        commit("SET_LOGIN", false);
        router.push("/").catch(() => {});
      } else {
        await window.axios
          .post("auth/me")
          .then(resp => {
            commit("SET_USER", resp.data);
            commit("SET_LOGIN", true);
          })
          .catch(e => dispatch("errores", e, { root: true }));
      }
    },
    async logout({ commit, dispatch }) {
      window.axios
        .post("auth/logout")
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1500 });
          commit("SET_LOGIN", false);
          commit("SET_USER", {});
          localStorage.clear();
          router.push("/").catch(() => {});
          commit("setErrors", [], { root: true });
          dispatch("reset", null, { root: true });
        })
        .catch(e => dispatch("errores", e, { root: true }));
    }
  }
};

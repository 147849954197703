<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text v-if="lote">
      <v-expansion-panels multiple>
        <Huerta />
        <Lote />
        <Producto />
        <Archivos />
        <Cortes />
        <Pagos />
        <Comisiones />
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="regresar">regresar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Huerta from "./panelHuerta";
import Lote from "./panelLote";
import Producto from "./panelProducto";
import Archivos from "./panelArchivos";
import Cortes from "./panelCortes";
import Pagos from "./PanelPagos";
import Comisiones from "./PanelComisionistas.vue";
export default {
  name: "Detalles",
  components: { Huerta, Lote, Producto, Archivos, Cortes, Pagos, Comisiones },
  computed: {
    ...mapState({
      lote: state => state.Lotes.resource
    })
  },
  mounted() {
    if (!this.lote) this.getLote(this.$route.params.id);
  },
  methods: {
    ...mapActions("Lotes", ["getLote"]),
    regresar() {
      this.$router.go(-1);
    }
  }
};
</script>

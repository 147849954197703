<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-row v-if="items.length">
        <v-col cols="12" xl="8" offset-xl="2">
          Última modificación el dia {{ fecha | date }} por {{ user }}
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="12" xl="8" offset-xl="2">
          <v-data-table
            dense
            mobile-breakpoint="0"
            :headers="headers"
            :items="items"
            class="elevation-1 mt-3"
            hide-default-footer
            :items-per-page="items.length"
          >
            <template v-slot:[`item.organico`]="{ item }">
              {{ item.organico | moneda }}
            </template>
            <template v-slot:[`item.convencional`]="{ item }">
              {{ item.convencional | moneda }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                icon
                color="success"
                :disabled="loader"
                @click="editar(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="dialog" max-width="600" persistent>
      <v-card>
        <v-card-title class="headline light-blue darken-4 text-white">
          Editando {{ pago.tipo_corte }}
        </v-card-title>
        <v-form @submit.prevent="actualizar">
          <v-card-text>
            <v-row class="mt-4">
              <v-col>
                <v-text-field
                  dense
                  outlined
                  type="number"
                  step="0.01"
                  label="Precio Kilo Orgánico"
                  v-model.number="pago.organico"
                  :error-messages="errors.organico"
                />
              </v-col>
              <v-col>
                <v-text-field
                  dense
                  outlined
                  type="number"
                  step="0.01"
                  label="Precio Kilo Convencional"
                  v-model.number="pago.convencional"
                  :error-messages="errors.convencional"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" :disabled="loader" type="submit">
              Guardar
            </v-btn>
            <v-btn color="error" @click="dialog = false" :disabled="loader">
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "PrecioFijo",
  data: () => ({
    headers: [
      { text: "Tipo de corte", value: "tipo_corte" },
      {
        text: " Kilo Orgánico",
        value: "organico",
        align: "end"
      },
      {
        text: " Kilo Convencional",
        value: "convencional",
        align: "end"
      },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    items: [],
    fecha: "",
    user: undefined,
    loader: undefined,
    dialog: false,
    pago: {}
  }),
  computed: {
    ...mapState({
      errors: state => state.errors
    })
  },
  mounted() {
    this.traer();
  },
  methods: {
    traer() {
      this.loader = true;
      window.axios
        .get("/Pagos/precios")
        .then(resp => {
          this.items = resp.data.collection;
          this.fecha = resp.data.fecha;
          this.user = resp.data.user;
        })
        .catch(e => {
          this.$store.dispatch("errores", e);
        })
        .finally(() => (this.loader = false));
    },
    editar(item) {
      this.$store.commit("setErrors", []);
      this.pago = Object.assign({}, item);
      this.dialog = true;
    },
    actualizar() {
      this.$store.commit("setErrors", []);
      this.loader = true;
      window.axios
        .put(`/Pagos/precios/${this.pago.id}`, this.pago)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1500 });
          this.items = resp.data.collection;
          this.fecha = resp.data.fecha;
          this.user = resp.data.user;
          this.dialog = false;
        })
        .catch(e => {
          this.$store.dispatch("errores", e);
        })
        .finally(() => (this.loader = false));
    }
  }
};
</script>
<style lang="scss" escoped>
.text-white {
  color: white;
}
</style>

require("./bootstrap");
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import { loadProgressBar } from "axios-progress-bar";
import "axios-progress-bar/dist/nprogress.css";
loadProgressBar();
import "./sass/style.scss";
import "./registerServiceWorker";

Vue.config.productionTip = false;
Vue.filter("date", function(value) {
  if (value) {
    let texto = window
      .moment(value)
      .locale("es-MX")
      .format("DD/MMM/YYYY");
    texto = texto.replace("ene.", "Ene");
    texto = texto.replace("feb.", "Feb");
    texto = texto.replace("mar.", "Mzo");
    texto = texto.replace("abr.", "Abr");
    texto = texto.replace("may.", "May");
    texto = texto.replace("jun.", "Jun");
    texto = texto.replace("jul.", "Jul");
    texto = texto.replace("ago.", "Ago");
    texto = texto.replace("sep.", "Sep");
    texto = texto.replace("oct.", "Oct");
    texto = texto.replace("nov.", "Nov");
    texto = texto.replace("dic.", "Dic");
    return texto;
  }
  return "";
});
Vue.filter("dateTime", function(value) {
  if (value) {
    let texto = window
      .moment(value)
      .locale("es-MX")
      .format("DD/MMM/YYYY hh:mm A");
    texto = texto.replace("ene.", "Ene");
    texto = texto.replace("feb.", "Feb");
    texto = texto.replace("mar.", "Mzo");
    texto = texto.replace("abr.", "Abr");
    texto = texto.replace("may.", "May");
    texto = texto.replace("jun.", "Jun");
    texto = texto.replace("jul.", "Jul");
    texto = texto.replace("ago.", "Ago");
    texto = texto.replace("sep.", "Sep");
    texto = texto.replace("oct.", "Oct");
    texto = texto.replace("nov.", "Nov");
    texto = texto.replace("dic.", "Dic");
    return texto;
  }

  return "";
});
Vue.filter("moneda", function(value) {
  if (!value) return "";
  if (isNaN(value)) value = parseFloat(value);
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });
  if (isNaN(value)) return "";
  var numberString = formatter
    .formatToParts(value)
    .map(({ type, value }) => {
      switch (type) {
        case "currency":
          return `${value} `;
        default:
          return value;
      }
    })
    .reduce((string, part) => string + part);
  return numberString;
});
Vue.filter("folio", function(value) {
  return String(value).padStart(5, "0");
});
Vue.filter("numero", function(value) {
  if (isNaN(value)) value = parseFloat(value);
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });
  if (isNaN(value)) return "";
  var numberString = formatter
    .formatToParts(value)
    .map(({ type, value }) => {
      switch (type) {
        case "currency":
          return ` `;

        default:
          return value;
      }
    })
    .reduce((string, part) => string + part);
  return numberString;
});
Vue.filter("decimal", function(value) {
  if (isNaN(value)) value = parseFloat(value);
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });
  if (isNaN(value)) return "";
  var numberString = formatter
    .formatToParts(value)
    .map(({ type, value }) => {
      switch (type) {
        case "currency":
          return ` `;
        default:
          return value;
      }
    })
    .reduce((string, part) => string + part);
  return numberString;
});
Vue.filter("capital", function(val) {
  if (typeof val !== "string") return "";
  return val.charAt(0).toUpperCase() + val.slice(1);
});
Vue.mixin({
  methods: {
    userCan(ability) {
      let abilities = store.state.Auth.abilities;
      let encontrado = abilities.find(x => {
        return x.name === "*" || x.name === ability;
      });
      if (encontrado) return true;
    },
    row_classes(item) {
      if (item.deleted_at || item.trashed) {
        return "red lighten-5"; //can also return multiple classes e.g ["orange","disabled"]
      }
    }
  }
});
new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount("#app");

<!-- eslint-disable -->
<template>
  <v-expansion-panel v-if="items">
    <v-expansion-panel-header color="blue lighten-4">
      Cuadrillas
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" xl="4">
          <h3 class="mt-5">Cortes Normales</h3>
        </v-col>

        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        class="elevation-1"
        :search.sync="search"
        :headers="headers"
        :items="normales"
      >
        <template v-slot:[`item.lote`]="{ item }">
          {{ item.lote | folio }}
        </template>
        <template v-slot:[`item.orden_corte`]="{ item }">
          {{ item.orden_corte | folio }}
        </template>
        <template v-slot:[`item.kilos`]="{ item }">
          {{ item.kilos | decimal }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
        <template v-slot:[`item.importe_cuadrilla`]="{ item }">
          {{ item.importe_cuadrilla | moneda }}
        </template>
        <template v-slot:[`item.iva_cuadrilla`]="{ item }">
          {{ item.iva_cuadrilla | moneda }}
        </template>
        <template v-slot:[`item.total_cuadrilla`]="{ item }">
          {{ item.total_cuadrilla | moneda }}
        </template>
      </v-data-table>
      <div class="mt-3">
        <v-row>
          <v-col cols="12" xl="4">
            <h3 class="mt-5">Cortes Mixtos</h3>
          </v-col>

          <v-col cols="12" xl="4" offset-xl="4">
            <v-container>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Busqueda"
                single-line
                hide-details
              />
            </v-container>
          </v-col>
        </v-row>
        <v-data-table
          dense
          class="elevation-1"
          :search.sync="search"
          :headers="headers"
          :items="mixtos"
        >
          <template v-slot:[`item.lote`]="{ item }">
            {{ item.lote | folio }}
          </template>
          <template v-slot:[`item.orden_corte`]="{ item }">
            {{ item.orden_corte | folio }}
          </template>
          <template v-slot:[`item.kilos`]="{ item }">
            {{ item.kilos | decimal }}
          </template>
          <template v-slot:[`item.fecha`]="{ item }">
            {{ item.fecha | date }}
          </template>
          <template v-slot:[`item.importe_cuadrilla`]="{ item }">
            {{ item.importe_cuadrilla | moneda }}
          </template>
          <template v-slot:[`item.iva_cuadrilla`]="{ item }">
            {{ item.iva_cuadrilla | moneda }}
          </template>
          <template v-slot:[`item.total_cuadrilla`]="{ item }">
            {{ item.total_cuadrilla | moneda }}
          </template>
        </v-data-table>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Cuadrillas",
  data: () => ({
    headers: [
      { text: "Lote", value: "lote" },
      { text: "Fecha", value: "fecha" },
      { text: "Cuadrilla", value: "cuadrilla" },
      { text: "Orden de Corte", value: "orden_corte" },
      { text: "Huerta", value: "huerta" },
      { text: "Kilos", value: "kilos", align: "end" },
      { text: "Importe", value: "importe_cuadrilla", align: "end" },
      { text: "IVA", value: "iva_cuadrilla", align: "end" },
      { text: "TOTAL", value: "total_cuadrilla", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    search: undefined,
    perPage: 15
  }),
  computed: {
    ...mapState({
      items: state => state.Calculadora.cortes
    }),
    normales() {
      return this.items.filter(x => !x.mixto);
    },
    mixtos() {
      return this.items.filter(x => x.mixto);
    }
  }
};
</script>

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text >
      <v-expansion-panels multiple>
        <Lotes />
        <v-expansion-panel>
          <v-expansion-panel-header color="green lighten-4">
            Pagos
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            tabla con los pagos
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="cancelar">regresar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Lotes from "./panelLotes";
export default {
  name: "Historial",
  components: { Lotes },
  computed: {
    ...mapState({
      historial: state => state.Huertas.historial
    })
  },
  mounted() {
    if (!this.historial) {
      this.historialHuerta(this.$route.params.id);
    }
  },
  methods: {
    ...mapActions("Huertas", ["historialHuerta"]),
    cancelar() {
      this.$router.push({ name: "huertas" }).catch(() => {});
    }
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel-content',[_c('v-row',[_c('v-col',{staticClass:"mt-3"},[_c('v-btn',{staticClass:"mt-5",attrs:{"small":"","color":"success"},on:{"click":_vm.nuevo}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-3",attrs:{"dense":"","mobile-breakpoint":"0","hide-default-footer":"","headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fecha))+" ")]}},{key:"item.cuadrilla",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.nombreCuadrilla(item.cuadrilla_id))+" ")]}},{key:"item.tipo_caja",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capital")(item.tipo_caja))+" ")]}},{key:"item.peso_promedio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("decimal")((item.peso_neto / item.cajas)))+" ")]}},{key:"item.peso_neto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("decimal")(item.peso_neto))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.editar(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.eliminar(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
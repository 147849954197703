import router from "../../router";
const url = "/Pagos/cuadrillas";
const initialState = () => ({
  collection: undefined,
  resource: {},
  loader: false,
  cortes: []
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },

    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_RESOURCE(state, payload) {
      state.resource = payload;
    },
    SET_LOADER(state, payload) {
      state.loader = payload;
    },
    SET_CORTES(state, payload) {
      state.cortes = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch }) {
      commit("SET_COLLECTION", []);
      commit("SET_LOADER", true);
      window.axios
        .get(url)
        .then(resp => {
          commit("SET_COLLECTION", resp.data.collection);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    nueva({ commit }) {
      commit("SET_RESOURCE", {});
      commit("setErrors", [], { root: true });
      router.push({ name: "cuadrillas.create" });
    },
    editar({ commit }, item) {
      let nombre = `Editando Cuadrilla ${item.nombre}`;
      commit("SET_RESOURCE", Object.assign({}, item));
      commit("setErrors", [], { root: true });
      router.push({ name: "cuadrillas.edit", params: { id: item.id, nombre } });
    },
    async sendCuadrilla({ commit, dispatch, state }) {
      commit("setErrors", [], { root: true });
      commit("SET_LOADER", true);
      let cuadrilla = state.resource;
      if (!cuadrilla.id) {
        await window.axios
          .post(url, cuadrilla)
          .then(resp => {
            window.swal(resp.data.message, { icon: "success", timer: 1500 });
            commit("SET_COLLECTION", resp.data.collection);
            router.push({ name: "cuadrillas" }).catch(() => {});
          })
          .catch(e => dispatch("errores", e, { root: true }))
          .finally(() => commit("SET_LOADER", false));
      } else {
        await window.axios
          .put(`${url}/${cuadrilla.id}`, cuadrilla)
          .then(resp => {
            window.swal(resp.data.message, { icon: "success", timer: 1500 });
            commit("SET_COLLECTION", resp.data.collection);
            router.push({ name: "cuadrillas" }).catch(() => {});
          })
          .catch(e => dispatch("errores", e, { root: true }))
          .finally(() => commit("SET_LOADER", false));
      }
    },
    async deshabilitar({ commit, dispatch }, id) {
      commit("SET_LOADER", true);
      await window.axios
        .delete(`${url}/${id}`)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1500 });
          commit("SET_COLLECTION", resp.data.collection);
          router.push({ name: "cuadrillas" }).catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async habilitar({ commit, dispatch }, id) {
      commit("SET_LOADER", true);
      await window.axios
        .post(`${url}/habilitar/${id}`)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1500 });
          commit("SET_COLLECTION", resp.data.collection);
          router.push({ name: "cuadrillas" }).catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async historial({ commit, dispatch }, cuadrilla) {
      commit("SET_RESOURCE", cuadrilla);
      let nombre = `Historial de ${cuadrilla.nombre}`,
        id = cuadrilla.id;
      await window.axios
        .get(`${url}/historial/${id}`)
        .then(resp => {
          commit("SET_CORTES", resp.data.cortes);
          router
            .push({ name: "cuadrillas.historial", params: { id, nombre } })
            .catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    }
  }
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"mt-5 ml-3",attrs:{"small":"","color":"info","disabled":_vm.loader},on:{"click":_vm.getAll}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-btn',{staticClass:"mt-5 ml-3",attrs:{"small":"","color":"success","disabled":_vm.loader},on:{"click":_vm.nuevo}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-col',{attrs:{"cols":"12","xl":"4","offset-xl":"4"}},[_c('v-container',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Busqueda","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","items-per-page":_vm.perPage,"headers":_vm.headers,"items":_vm.items,"item-class":_vm.row_classes,"search":_vm.search,"mobile-breakpoint":"0"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}},{key:"item.iva",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.iva))+" ")]}},{key:"item.retencion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.retencion))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.total))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.deleted_at)?[_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.restaurar(item)}}},[_c('v-icon',[_vm._v("mdi-delete-restore")])],1)]:[_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.editar(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.eliminar(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
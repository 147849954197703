<!-- eslint-disable -->
<template>
  <v-expansion-panel-content>
    <v-card class="mt-3">
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-form @submit.prevent="storeCorte(corte)">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                dense
                outlined
                type="date"
                label="Fecha"
                v-model="corte.fecha"
                :error-messages="errors.fecha"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                dense
                outlined
                label="Folio"
                v-model="corte.folio"
                :error-messages="errors.folio"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                dense
                outlined
                label="Copref BICO"
                v-model="corte.copref_bico"
                :error-messages="errors.copref_bico"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                dense
                outlined
                label="Ticket"
                v-model="corte.ticket"
                :error-messages="errors.ticket"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2">
              <v-text-field
                dense
                outlined
                type="number"
                step="0.01"
                label="Peso Bruto"
                v-model.number="corte.peso_bruto"
                :error-messages="errors.peso_bruto"
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                dense
                outlined
                type="number"
                step="0.01"
                label="Peso Tara"
                v-model.number="corte.peso_tara"
                :error-messages="errors.peso_tara"
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                dense
                outlined
                type="number"
                step="0.01"
                label="Tara Cajas"
                v-model.number="corte.tara_cajas"
                :error-messages="errors.tara_cajas"
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                dense
                outlined
                type="number"
                step="0.01"
                label="Peso Muestra"
                v-model.number="corte.peso_muestra"
                :error-messages="errors.peso_muestra"
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                dense
                outlined
                type="number"
                step="0.01"
                label="Peso Neto"
                v-model.number="corte.peso_neto"
                :error-messages="errors.peso_neto"
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                dense
                outlined
                type="number"
                step="0.01"
                label="Pesada Productor"
                v-model.number="corte.pesada_productor"
                :error-messages="errors.pesada_productor"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                dense
                outlined
                label="Transportista"
                v-model="corte.transportista"
                :error-messages="errors.transportista"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                dense
                outlined
                label="Chofer"
                v-model="corte.chofer"
                :error-messages="errors.chofer"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                dense
                outlined
                label="Placas"
                v-model="corte.placas"
                :error-messages="errors.placas"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-select
                dense
                outlined
                label="Cuadrilla"
                v-model="corte.cuadrilla_id"
                :items="itemsCuadrillas"
                item-value="id"
                item-text="nombre"
                :error-messages="errors.cuadrilla_id"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                dense
                outlined
                label="Orden de Corte"
                v-model="corte.orden_corte"
                :error-messages="errors.orden_corte"
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                dense
                outlined
                type="number"
                label="Cajas"
                v-model.number="corte.cajas"
                :error-messages="errors.cajas"
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-select
                dense
                outlined
                label="Tipo de Caja"
                v-model="corte.tipo_caja"
                :items="tipos_caja"
                :error-messages="errors.tipo_caja"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-checkbox v-model="corte.mixto" :label="`Lote Mixto`" />
            </v-col>
            <v-col cols="12" md="3">
              <v-checkbox
                v-model="corte.cuadrilla_apoyo"
                :label="`Cuadrilla de Apoyo `"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                outlined
                label="Observaciones"
                v-model="corte.observaciones"
              />
            </v-col>
          </v-row>
          <v-row v-if="!corte.id">
            <v-col cols="12" md="4">
              <v-file-input
                class="mt-5"
                v-model="file"
                dense
                outlined
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                placeholder="Seleccionar archivo a procesar"
                prepend-icon="mdi-attachment"
                label="Procesar Archivo"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn small color="error" @click="cancelar">cancelar</v-btn>
          <v-btn small color="success" v-if="modificado" type="submit">
            {{ title2 }}
          </v-btn>
          <v-btn small color="success" v-if="file" @click="procesar">
            Procesar Archivo
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-expansion-panel-content>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Form",
  data: () => ({
    original: {},
    tipos_caja: [
      { value: "chica", text: "Chica" },
      { value: "mediana", text: "Mediana" },
      { value: "grande", text: "Grande" }
    ],
    file: undefined
  }),
  mounted() {
    this.original = Object.assign({}, this.corte);
    this.$store.commit("setErrors", []);
  },
  computed: {
    ...mapState({
      corte: state => state.Lotes.corte,
      cuadrillas: state => state.Lotes.resource.cuadrillas,
      lote: state => state.Lotes.resource.lote,
      errors: state => state.errors
    }),
    title() {
      let title = "Nuevo Corte";
      if (this.corte.id && this.corte.id !== 1) title = "Editando Corte";
      return title;
    },
    itemsCuadrillas() {
      let data = [];
      if (this.corte.id) {
        data = this.cuadrillas;
        if (this.corte.id == 1) {
          data = this.cuadrillas.filter(x => !x.deleted_at);
        }
      } else {
        data = this.cuadrillas.filter(x => !x.deleted_at);
      }
      return data;
    },
    modificado() {
      return JSON.stringify(this.corte) !== JSON.stringify(this.original);
    },
    title2() {
      if (!this.original) return "";
      return this.original.id ? "Actualizar" : "guardar";
    }
  },
  methods: {
    cancelar() {
      this.$store.commit("Lotes/SET_ACTION", undefined);
    },
    procesar() {
      this.$store.commit("setErrors", []);
      let formData = new FormData();
      formData.append("file", this.file);
      window.axios
        .post(`/Pagos/lotes/subirArchivo/${this.lote.id}`, formData)
        .then(resp => {
          this.$store.commit("Lotes/SET_CORTE", resp.data);
          this.file = undefined;
        })
        .catch(e => {
          window.swal(e.response.data.message, { icon: "error" });
          this.file = undefined;
          this.$store.commit("Lotes/SET_CORTE", {});
        });
    },
    ...mapActions("Lotes", ["storeCorte"])
  },
  watch: {
    file: function(val) {
      if (val) this.$store.commit("Lotes/SET_CORTE", {});
    }
  }
};
</script>

<!-- eslint-disable -->
<template>
  <div>
    <v-row class="mt-3">
      <v-col cols="12" md="6">
        <v-select
          dense
          outlined
          small-chips
          clearable
          label="Seleccione una Cuadrilla"
          :items="cuadrillas"
          v-model="cuadrilla"
        />
      </v-col>
    </v-row>
    <v-data-table
      dense
      class="elevation-1"
      :search.sync="search"
      :headers="headers"
      :items="items"
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.lote`]="{ item }">
        {{ item.lote | folio }}
      </template>
      <template v-slot:[`item.orden_corte`]="{ item }">
        {{ item.orden_corte | folio }}
      </template>
      <template v-slot:[`item.kilos`]="{ item }">
        {{ item.kilos | decimal }}
      </template>
      <template v-slot:[`item.fecha`]="{ item }">
        {{ item.fecha | date }}
      </template>
      <template v-slot:[`item.importe_cuadrilla`]="{ item }">
        {{ item.importe_cuadrilla | moneda }}
      </template>
      <template v-slot:[`item.iva_cuadrilla`]="{ item }">
        {{ item.iva_cuadrilla | moneda }}
      </template>
      <template v-slot:[`item.total_cuadrilla`]="{ item }">
        {{ item.total_cuadrilla | moneda }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-simple-checkbox
          v-model="item.pagar"
          :ripple="false"
          color="success"
        />
      </template>
      <template v-slot:[`item.mixto`]="{ item }">
        {{ item.mixto ? "SI" : "" }}
      </template>
    </v-data-table>
    <v-row class="mt-3" v-if="seleccionados.length">
      <v-col>
        Total a Pagar: {{ aPagar | moneda }}
        <v-btn class="ml-2" color="error" @click="limpiar">Limpiar</v-btn>
        <v-btn class="ml-2" color="primary" @click="pagar">
          Pagar
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="dialog" max-width="650px">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Datos del Pago
        </v-card-title>
        <v-form @submit.prevent="guardar">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  dense
                  outlined
                  label="Fecha de Pago"
                  v-model="pago.fecha"
                  type="date"
                  :max="today"
                  :error-messages="errors.fecha"
                />
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  dense
                  outlined
                  v-model="pago.beneficiario"
                  label="Beneficiario"
                  id="id"
                  :error-messages="errors.beneficiario"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  outlined
                  label="Factura"
                  v-model="pago.factura"
                  :error-messages="errors.factura"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  dense
                  outlined
                  small-chips
                  clearable
                  label="Forma de Pago"
                  :items="formas"
                  v-model="pago.forma_pago"
                  :error-messages="errors.forma_pago"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                v-if="pago.forma_pago !== 'Efectivo' && pago.forma_pago"
              >
                <v-text-field
                  dense
                  outlined
                  label="Referencia"
                  v-model="pago.referencia"
                  :error-messages="errors.referencia"
                />
              </v-col>
              <v-col>
                <v-text-field
                  dense
                  outlined
                  label="Importe Total a Pagar"
                  v-model="aPagar"
                  type="number"
                  disabled
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="limpiar" :disabled="loader">
              cancelar
            </v-btn>
            <v-btn color="success" type="submit" :disabled="loader">
              enviar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Cortes",
  data: () => ({
    headers: [
      { text: "Lote", value: "lote" },
      { text: "Fecha", value: "fecha" },
      { text: "Huerta", value: "huerta" },
      { text: "Orden de Corte", value: "orden_corte" },
      { text: "Mixto", value: "mixto" },
      { text: "Kilos", value: "kilos", align: "end" },
      { text: "Importe", value: "importe_cuadrilla", align: "end" },
      { text: "IVA", value: "iva_cuadrilla", align: "end" },
      { text: "TOTAL", value: "total_cuadrilla", align: "end" },
      { text: "Seleccionar", value: "actions", align: "end" }
    ],
    search: undefined,
    cuadrilla: undefined,
    pago: {}
  }),
  computed: {
    ...mapState({
      cortes: state => state.Calculadora.cortes,
      dialog: state => state.Pagos.corte,
      loader: state => state.Pagos.loader,
      errors: state => state.errors
    }),
    listos() {
      return this.cortes.filter(
        x => x.cuadrilla && x.importe_cuadrilla > 0 && x.pagarCorte
      );
    },
    items() {
      return this.listos.filter(x => x.cuadrilla == this.cuadrilla);
    },
    cuadrillas() {
      return [...new Set(this.listos.map(x => x.cuadrilla))].sort();
    },
    seleccionados() {
      return this.items.filter(x => x.pagar);
    },
    aPagar() {
      let importe = window._.sumBy(this.seleccionados, "total_cuadrilla");
      return importe.toFixed(2);
    },
    today() {
      return new Date().toISOString().split("T")[0];
    },
    formas() {
      let array = ["Efectivo", "Cheque", "Transferencia"];
      return array.sort();
    }
  },
  mounted() {
    this.limpiar();
  },
  methods: {
    ...mapActions("Pagos", ["storePago"]),
    limpiar() {
      this.$store.commit("Pagos/setCorte");
      this.pago = { tipo: "Corte", fecha: this.today };
      let listos = this.cortes.filter(x => x.pagar);
      listos.forEach(x => (x.pagar = false));
      this.cuadrilla = undefined;
    },
    pagar() {
      this.pago.beneficiario = this.cuadrilla;
      this.$store.commit("Pagos/setCorte", true);
      this.$store.commit("setErrors");
    },
    guardar() {
      this.pago.items = this.seleccionados;
      this.storePago(this.pago);
    }
  }
};
</script>

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="blue lighten-4">
      Comisionistas
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" xl="4" offset-xl="8">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        :search.sync="search"
        class="elevation-1"
      >
        <template v-slot:[`item.lote.lote`]="{ item }">
          {{ item.lote.lote | folio }}
        </template>
        <template v-slot:[`item.lote.fecha`]="{ item }">
          {{ item.lote.fecha | date }}
        </template>
        <template v-slot:[`item.kilos`]="{ item }">
          {{ item.kilos | numero }}
        </template>
        <template v-slot:[`item.precio`]="{ item }">
          {{ item.precio | moneda }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Comisionistas",
  computed: {
    ...mapState({
      items: state => state.Calculadora.comisionistas
    })
  },
  data: () => ({
    headers: [
      { text: "Fecha", value: "lote.fecha" },
      { text: "Comisionista", value: "comisionista.nombre" },
      { text: "Huerta", value: "lote.huerta.nombre" },
      { text: "Lote", value: "lote.lote" },
      { text: "Corte", value: "corte.folio" },
      { text: "Kilos", value: "kilos", align: "end" },
      { text: "Precio por kilo", value: "precio", align: "end" },
      { text: "Importe", value: "importe", align: "end" }
    ],
    search: undefined
  })
};
</script>

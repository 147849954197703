<!-- eslint-disable -->
<template>
  <v-expansion-panel v-if="items.length">
    <v-expansion-panel-header color="blue lighten-4">
      Pagos
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="mt-3">
        <v-col cols="12" md="4" offset-md="8">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        class="elevation-1 mt-3"
        :search.sync="search"
      >
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | folio }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Pagos",
  data: () => ({
    headers: [
      { text: "Folio", value: "id" },
      { text: "Fecha", value: "fecha" },
      
      { text: "Tipo de Pago", value: "tipo" },
      { text: "Factura", value: "factura" },
      { text: "Beneficiario", value: "beneficiario" },
      { text: "Forma de Pago", value: "forma_pago" },
      { text: "Referencia", value: "referencia" },
      { text: "Importe", value: "importe", align: "end" }
    ],
    search: undefined
  }),
  computed: {
    ...mapState({
      items: state => state.Lotes.resource.pagos
    })
  },
  mounted() {
    //
  },
  methods: {
    //
  }
};
</script>

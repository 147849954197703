import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";
import router from "../router";
Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  state: {
    appName: "Zamgod Solutions",
    errors: [],
    loader: false
  },
  mutations: {
    setErrors(state, payload = []) {
      state.errors = payload;
    },
    setLoader(state, payload = true) {
      state.loader = payload;
    }
  },
  actions: {
    reset({ commit }) {
      // resets state of all the modules
      Object.keys(modules).forEach(moduleName => {
        commit(`${moduleName}/SET_INITIAL_STATE`);
      });
    },
    errores({ commit, dispatch }, e) {
      const status = e.response.status;
      switch (status) {
        case 403:
          window.swal("No cuentas con los permisos suficientes", {
            icon: "error",
            timer: 1500
          });
          break;
        case 401:
          window.swal("La sesion expiró", {
            icon: "error",
            timer: 1500
          });
          dispatch("reset");
          localStorage.clear();
          window.axios.defaults.headers.common.Authorization =
            "Bearer token-falso";
          router.push("/").catch(() => {});
          break;
        case 422:
          commit("setErrors", e.response.data.errors);
          if (!e.response.data.errors) {
            window.swal(e.response.data.message, {
              icon: "error",
              timer: 2000
            });
          }
          break;
        default:
          window.swal(e.response.data.message, { icon: "error", timer: 1500 });
          break;
      }
    },
    mensaje(context, mensaje) {
      window.swal(mensaje, { icon: "success", timer: 1500 });
    }
  },
  strict: false
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"blue lighten-4"}},[_vm._v(" Lotes ")]),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"mt-5 ml-3",attrs:{"small":"","color":"info"},on:{"click":_vm.actualizar}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-col',{attrs:{"cols":"12","xl":"4","offset-xl":"4"}},[_c('v-container',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Busqueda","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","mobile-breakpoint":"0","headers":_vm.headers,"search":_vm.search,"items":_vm.items},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.lote",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("folio")(item.lote))+" ")]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fecha))+" ")]}},{key:"item.fecha_corte",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fecha_corte))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.detalle(item)}}},[_c('v-icon',[_vm._v("mdi-information")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Archivos del Lote
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="mt-3">
        <v-col cols="12" xl="6">
          <v-file-input
            class="mt-5"
            v-model="file"
            dense
            outlined
            placeholder="Seleccionar archivo para subir"
            prepend-icon="mdi-attachment"
            label="Subir Archivo"
          />
        </v-col>
        <v-col cols="12" xl="2">
          <v-btn
            class="mt-5"
            color="success"
            v-if="file"
            :disabled="isLoading"
            @click="enviar"
          >
            Subir
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        dense
        hide-default-footer
        mobile-breakpoint="0"
        class="elevation-1"
        :headers="headers"
        :items="items"
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | dateTime }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="success"
            v-if="item.link"
            :href="item.link"
            target="_blank"
            :disabled="isLoading"
          >
            <v-icon>mdi-cloud-download</v-icon>
          </v-btn>
          <v-btn
            icon
            color="error"
            @click="eliminar(item)"
            :disabled="isLoading"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Archivos",
  computed: {
    ...mapState({
      isLoading: state => state.Lotes.loader,
      items: state => state.Lotes.resource.archivos
    })
  },
  data: () => ({
    headers: [
      { text: "Archivo", value: "archivo" },
      { text: "Fecha/Hora", value: "created_at" },
      { text: "Usuario", value: "usuario" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    file: undefined
  }),
  methods: {
    ...mapActions("Lotes", ["uploadFile", "deleteFile"]),
    enviar() {
      let formData = new FormData();
      formData.append("file", this.file);
      this.uploadFile(formData);
    },
    eliminar(item) {
      let pregunta = `¿Eliminar el archivo ${item.archivo}?`;
      window
        .swal({
          title: pregunta,
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmed => {
          if (confirmed) {
            this.deleteFile(item.id);
          }
        });
    }
  },
  watch: {
    items: function() {
      this.file = undefined;
    }
  }
};
</script>

<!-- eslint-disable -->
<template>
  <div>
    <v-navigation-drawer v-model="drawer" app>
      <div class="text-center py-5">
        <img :src="require('./logo.jpeg')" width="40%" alt="" />
      </div>
      <v-list dense shaped>
        <v-list-item-group color="primary">
          <v-list-group :value="false" prepend-icon="mdi-folder">
            <template v-slot:activator>
              <v-list-item-title>Catálogos</v-list-item-title>
            </template>
            <v-list-item
              link
              :to="{ name: item.link }"
              v-for="item in catalogos"
              :key="item.title"
            >
              <v-list-item-action>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item
            :href="url"
            target="_blank"
            v-if="user.email == 'developer@zamgod.mx'"
          >
            <v-list-item-action>
              <v-icon>mdi-bug</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logs</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            :to="{ name: item.link }"
            v-for="item in otros"
            :key="item.title"
          >
            <v-list-item-action>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="primary">
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        class="white--text"
      ></v-app-bar-nav-icon>

      <v-toolbar-title class="white--text" v-text="name"></v-toolbar-title>
    </v-app-bar>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    drawer: null,
    catalogos: [
      { title: "Huertas", link: "huertas", icon: "mdi-pine-tree" },
      { title: "Cuadrillas", link: "cuadrillas", icon: "mdi-truck-fast" },
      { title: "Comisionistas", link: "comisionistas", icon: "mdi-account" },
      { title: "Tarifas Fletes", link: "tarifas", icon: "mdi-truck-check" },
      { title: "Precios Fijos", link: "preciosFijos", icon: "mdi-cash-usd" }
    ],
    otros: [
      { title: "Inicio", link: "inicio", icon: "mdi-home" },
      { title: "Lotes", link: "lotes", icon: "mdi-apps" },
      { title: "Calculadora", link: "calculadora", icon: "mdi-calculator" },
      { title: "Salidas en Falso", link: "salidasFalso", icon: "mdi-truck-delivery" },
      { title: "Pagos", link: "pagos", icon: "mdi-currency-usd-circle" },
      { title: "Salir", link: "logout", icon: "mdi-power" }
    ]
  }),
  computed: {
    ...mapState({
      user: state => state.Auth.user
    }),
    name() {
      if (this.$route.params.nombre) return this.$route.params.nombre;
      return this.$route.meta ? this.$route.meta.name : "";
    },
    url() {
      let urlBase = process.env.VUE_APP_BASE_URL,
        url = urlBase.substring(0, urlBase.length - 4);
      return url + "log-viewer/logs";
    }
  }
};
</script>

<!-- eslint-disable -->
<template>
  <div>
    Formulario para pagar a comisionista
  </div>
</template>

<script>
  export default {
    name:'ComisionistaForm'
  }
</script>

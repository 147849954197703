<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-form @submit.prevent="sendCuadrilla">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              outlined
              label="Nombre"
              v-model="cuadrilla.nombre"
              :error-messages="errors.nombre"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-combobox
              :items="empresas"
              dense
              outlined
              small-chips
              clearable
              label="Empresa"
              v-model="cuadrilla.empresa"
              :error-messages="errors.empresa"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              outlined
              label="Correo Electrónico"
              type="email"
              v-model="cuadrilla.email"
              :error-messages="errors.email"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              outlined
              label="Teléfono"
              v-model="cuadrilla.telefono"
              :error-messages="errors.telefono"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              outlined
              label="Contacto"
              v-model="cuadrilla.contacto"
              :error-messages="errors.contacto"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              dense
              outlined
              label="Pago Por Dia"
              type="number"
              step="0.01"
              v-model.number="cuadrilla.pago_dia"
              :error-messages="errors.pago_dia"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              dense
              outlined
              label="Pago Por Kilo"
              type="number"
              step="0.01"
              v-model.number="cuadrilla.pago_kilo"
              :error-messages="errors.pago_kilo"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" @click="cancelar" :disabled="loader">
          cancelar
        </v-btn>
        <v-btn
          color="success"
          type="submit"
          :disabled="loader"
          v-if="modificado"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Form",
  data: () => ({
    original: undefined
  }),
  computed: {
    ...mapState({
      errors: state => state.errors,
      cuadrilla: state => state.Cuadrillas.resource,
      loader: state => state.Cuadrillas.loader,
      collection: state => state.Cuadrillas.collection
    }),
    modificado() {
      return JSON.stringify(this.cuadrilla) !== JSON.stringify(this.original);
    },
    empresas() {
      let cuadrillas = [...new Set(this.collection.map(x => x.empresa))].sort();
      return cuadrillas;
    }
  },
  mounted() {
    this.original = Object.assign({}, this.cuadrilla);
  },
  methods: {
    ...mapActions("Cuadrillas", ["sendCuadrilla"]),
    cancelar() {
      this.$router.push({ name: "cuadrillas" });
    }
  }
};
</script>

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="orange lighten-4">
      Cortes del Lote
    </v-expansion-panel-header>
    <Tabla v-if="!action" />
    <Form v-else />
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
import Tabla from "./tablaCortes";
import Form from "./Form";
export default {
  name: "Cortes",
  components: { Tabla, Form },
  computed: {
    ...mapState({
      action: state => state.Lotes.action
    })
  }
};
</script>

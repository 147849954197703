import Index from "./../views/tarifas/Index.vue";
import Form from "./../views/tarifas/Form.vue";
import PagoFijo from "./../views/PagoFijo.vue";
const routes = [
  {
    path: "/Tarifas-Fletes",
    name: "tarifas",
    component: Index,
    meta: {
      name: "Tarifas Fletes"
    }
  },
  {
    path: "/Tarifas-Fletes/nueva",
    name: "tarifas.create",
    component: Form,
    meta: {
      name: "Nueva Tarifa Fletes"
    }
  },
  {
    path: "/Tarifas-Fletes/editar/:id",
    name: "tarifas.edit",
    component: Form,
    meta: {
      name: "Editar Tarifa Fletes"
    }
  },
  {
    path: "/Precios-Fijos",
    name: "preciosFijos",
    component: PagoFijo,
    meta: {
      name: "Precios Fijos por tipo de corte"
    }
  }
];
export default routes;

<!-- eslint-disable -->
<template>
  <v-expansion-panel v-if="items">
    <v-expansion-panel-header color="orange lighten-4">
      Productores
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" xl="4" offset-xl="8">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        class="elevation-1"
        :search.sync="search"
        :headers="headers"
        :items="items"
      >
        <template v-slot:[`item.lote`]="{ item }">
          {{ item.lote | folio }}
        </template>
        <template v-slot:[`item.orden_corte`]="{ item }">
          {{ item.orden_corte | folio }}
        </template>
        <template v-slot:[`item.kilos_procesados`]="{ item }">
          {{ item.kilos_procesados | decimal }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
        <template v-slot:[`item.precio_kilo`]="{ item }">
          {{ item.precio_kilo | moneda }}
        </template>
        <template v-slot:[`item.importe_productor`]="{ item }">
          {{ item.importe_productor | moneda }}
        </template>
        <template v-slot:[`item.total_cuadrilla`]="{ item }">
          {{ item.total_cuadrilla | moneda }}
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Productores",
  data: () => ({
    headers: [
      { text: "Lote", value: "lote" },
      { text: "Fecha", value: "fecha" },
      { text: "Huerta", value: "huerta" },
      { text: "Productor", value: "productor" },
      { text: "Tipo de Corte", value: "tipo_corte" },
      { text: "Forma de Pago", value: "forma_pago_productor" },
      { text: "Precio Kilo", value: "precio_kilo", align: "end" },
      { text: "Kilos Procesados", value: "kilos_procesados", align: "end" },
      { text: "Importe", value: "importe_productor", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    search: undefined,
    perPage: 15
  }),
  computed: {
    ...mapState({
      items: state => state.Calculadora.lotes
    })
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"blue lighten-4"}},[_vm._v(" Comisiones ")]),_c('v-expansion-panel-content',[_c('v-row',{staticClass:"mt-3"},[_c('v-col',[_c('v-btn',{attrs:{"small":"","color":"success","disabled":_vm.loader},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-2",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","mobile-breakpoint":"0","loading":_vm.loader,"loading-text":"Cargando datos"},scopedSlots:_vm._u([{key:"item.kilos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numero")(item.kilos))+" ")]}},{key:"item.precio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.precio))+" ")]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")((item.precio * item.kilos)))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.status)?_c('v-btn',{attrs:{"icon":"","color":"error","disabled":_vm.loader},on:{"click":function($event){return _vm.eliminar(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)})],1),(_vm.dialog)?_c('DialogComision',{attrs:{"dialog":_vm.dialog},on:{"cancelar":function($event){_vm.dialog = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<!-- eslint-disable -->
<template>
  <v-card>
    <v-form @submit.prevent="updateHuerta">
      <v-card-text v-if="huerta">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              dense clearable
              outlined
              type="text"
              label="Nombre de la Huerta"
              v-model="huerta.nombre"
              :error-messages="errors.nombre"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              dense clearable
              outlined
              type="text"
              label="Nombre del Productor"
              v-model="huerta.productor"
              :error-messages="errors.productor"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              dense
              outlined
              small-chips
              clearable
              label="Tipo de Producto"
              :items="tipos"
              v-model="huerta.tipo_huerta"
              :error-messages="errors.tipo_huerta"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              dense clearable
              outlined
              type="text"
              label="Localidad"
              v-model="huerta.localidad"
              :error-messages="errors.localidad"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              dense clearable
              outlined
              type="text"
              label="Municipio"
              v-model="huerta.municipio"
              :error-messages="errors.municipio"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              dense clearable
              outlined
              type="text"
              label="Registro Sagarpa"
              v-model="huerta.sagarpa"
              :error-messages="errors.sagarpa"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              dense clearable
              outlined
              type="text"
              label="N° Global Gap"
              v-model="huerta.ggn"
              :error-messages="errors.ggn"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              dense clearable
              outlined
              label="RFC del productor"
              v-model="huerta.rfc"
              :error-messages="errors.rfc" hide-details="auto"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              dense clearable
              outlined
              label="Nombre del Contador"
              v-model="huerta.contador"
              :error-messages="errors.contador" hide-details="auto"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              dense clearable
              outlined
              label="Correo"
              v-model="huerta.email"
              :error-messages="errors.email" hide-details="auto"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              dense clearable
              outlined
              label="Teléfono "
              v-model="huerta.telefono"
              :error-messages="errors.telefono"
              hide-details="auto"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="success"
          :disabled="loading"
          type="submit"
          v-if="modificado"
          >guardar</v-btn
        >
        <v-btn color="error" :disabled="loading" @click="cancelar">
          cancelar
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Detalles",
  data: () => ({
    original: undefined,
    tipos: ["CONVENCIONAL", "ORGÁNICO"]
  }),
  computed: {
    ...mapState({
      huerta: state => state.Huertas.resource,
      loading: state => state.Huertas.loader,
      errors: state => state.errors
    }),
    modificado: function() {
      return JSON.stringify(this.huerta) !== JSON.stringify(this.original);
    }
  },
  mounted() {
    if (!this.huerta) {
      this.getHuerta(this.$route.params.id);
    } else {
      this.original = Object.assign({}, this.huerta);
    }
  },
  watch: {
    huerta: function(val) {
      this.original = Object.assign({}, val);
    }
  },
  methods: {
    ...mapActions("Huertas", ["getHuerta", "updateHuerta"]),
    cancelar() {
      this.$router.push({ name: "huertas" }).catch(() => {});
    }
  }
};
</script>

import Comisionistas from "./../views/comisionistas/index.vue";
import Form from "./../views/comisionistas/Form.vue";
import Historial from './../views/comisionistas/Historial.vue';
const routes = [
  {
    path: "/Comisionistas",
    name: "comisionistas",
    component: Comisionistas,
    meta: {
      name: "Catálogo de Comisionistas"
    }
  },
  {
    path: "/Comisionistas/nuevo",
    name: "comisionistas.create",
    component: Form,
    meta: {
      name: "Nuevo Comisionista"
    }
  },
  {
    path: "/Comisionistas/editar/:id",
    name: "comisionistas.edit",
    component: Form,
    meta: {
      name: "Editar Comisionista"
    }
  },
  {
    path: "/Comisionistas/historial/:id",
    name: "comisionistas.historial",
    component: Historial,
    meta: {
      name: "Historial Comisionista"
    }
  }
];
export default routes;

<!-- eslint-disable -->
<template>
  <PagosProductor v-if="loteActual.id" />
  <div class="mt-3" v-else>
    <v-row>
      <v-col cols="12" md="8">
        <v-select
          dense
          outlined
          small-chips
          clearable
          :items="productores"
          v-model="productor"
          label="Productor"
        />
      </v-col>
    </v-row>
    <v-data-table
      dense
      class="elevation-1"
      :search.sync="search"
      :headers="headers"
      :items="items"
      v-if="items.length"
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.lote`]="{ item }">
        {{ item.lote | folio }}
      </template>
      <template v-slot:[`item.orden_corte`]="{ item }">
        {{ item.orden_corte | folio }}
      </template>
      <template v-slot:[`item.kilos_procesados`]="{ item }">
        {{ item.kilos_procesados | decimal }}
      </template>
      <template v-slot:[`item.fecha`]="{ item }">
        {{ item.fecha | date }}
      </template>
      <template v-slot:[`item.precio_kilo`]="{ item }">
        {{ item.precio_kilo | moneda }}
      </template>
      <template v-slot:[`item.importe_productor`]="{ item }">
        {{ item.importe_productor | moneda }}
      </template>
      <template v-slot:[`item.pago_real_productor`]="{ item }">
        {{ item.pago_real_productor | moneda }}
      </template>
      <template v-slot:[`item.pagos_productor`]="{ item }">
        {{ item.pagos_productor | moneda }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon color="success" @click="editar(item)" :disabled="loader">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          icon
          color="primary"
          :disabled="loader"
          v-if="
            item.pago_real_productor &&
              item.pago_real_productor !== item.pagos_productor
          "
          @click="pagar(item)"
        >
          <v-icon>mdi-currency-usd-circle</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="editando" persistent max-width="650px">
      <v-card>
        <v-card-title class="headline">
          {{ titulo(lote) }} Importe Real del lote {{ lote.lote }}
        </v-card-title>
        <v-form @submit.prevent="enviar">
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  dense
                  outlined
                  clearable
                  type="number"
                  step="0.01"
                  label="Iporte Real"
                  v-model.number="importeReal"
                  :error-messages="errors.importe"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" @click="cancelar" :disabled="loader">
              cancelar
            </v-btn>
            <v-btn
              color="success"
              type="submit"
              :disabled="loader"
              v-if="importeReal"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PagosProductor from "./PagosProductor";
export default {
  name: "Productor",
  components: { PagosProductor },
  data: () => ({
    productor: undefined,
    headers: [
      { text: "Lote", value: "lote" },
      { text: "Fecha", value: "fecha" },
      { text: "Huerta", value: "huerta" },
      { text: "Tipo de Corte", value: "tipo_corte" },
      { text: "Forma de Pago", value: "forma_pago_productor" },
      { text: "Precio Kilo", value: "precio_kilo", align: "end" },
      { text: "Kilos Procesados", value: "kilos_procesados", align: "end" },
      { text: "Importe Calculado", value: "importe_productor", align: "end" },
      { text: "Importe Real", value: "pago_real_productor", align: "end" },
      { text: "Pagos", value: "pagos_productor", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    search: undefined,
    importeReal: undefined,
    editando: false,
    lote: {},
    pagando: false
  }),
  computed: {
    ...mapState({
      lotes: state => state.Calculadora.lotes,
      loader: state => state.Calculadora.loader,
      errors: state => state.errors,
      fechas: state => state.Calculadora.fechas,
      loteActual: state => state.Pagos.lote
    }),
    productores() {
      return [...new Set(this.lotes.map(x => x.productor))].sort();
    },
    items() {
      return this.lotes.filter(x => x.productor == this.productor);
    }
  },
  methods: {
    editar(item) {
      this.$store.commit("setErrors", []);
      this.importeReal = item.pago_real_productor
        ? parseFloat(item.pago_real_productor)
        : parseFloat(item.importe_productor);

      this.editando = true;
      this.lote = item;
    },
    cancelar() {
      this.importeReal = undefined;
      this.editando = undefined;
      this.lote = {};
    },
    enviar() {
      this.$store.commit("Calculadora/SET_LOADER", true);
      window.axios
        .post(`/Pagos/pagos/updateLote/${this.lote.id}`, {
          importe: this.importeReal,
          inicio: this.fechas.inicio,
          fin: this.fechas.fin
        })
        .then(resp => {
          this.$store.commit("Calculadora/setLotes", resp.data.lotes);
          this.cancelar();
          window.swal(resp.data.message, { icon: "success", timer: 1500 });
        })
        .catch(e => this.$store.dispatch("errores", e, { root: true }))
        .finally(() => this.$store.commit("Calculadora/SET_LOADER", false));
    },
    titulo(item) {
      return item.pago_real_productor ? "Actualizar" : "Colocar";
    },
    pagar(item) {
      this.$store.commit("Pagos/setLote", item);
    }
  }
};
</script>

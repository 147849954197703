<!-- eslint-disable -->
<template>
  <v-expansion-panel v-if="cortes">
    <v-expansion-panel-header color="green lighten-4">
      Transportistas
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" xl="4" offset-xl="8">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        class="elevation-1"
        :search.sync="search"
        :headers="headers"
        :items="items"
        :item-class="rowClass"
      >
        <template v-slot:[`item.lote`]="{ item }">
          {{ item.lote | folio }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
        <template v-slot:[`item.importe_transportista`]="{ item }">
          {{ item.importe_transportista | moneda }}
        </template>
        <template v-slot:[`item.iva_transportista`]="{ item }">
          {{ item.iva_transportista | moneda }}
        </template>
        <template v-slot:[`item.total_transportista`]="{ item }">
          {{ item.total_transportista | moneda }}
        </template>
        <template v-slot:[`item.retencion_iva_transportista`]="{ item }">
          {{ item.retencion_iva_transportista | moneda }}
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Transportistas",
  data: () => ({
    headers: [
      { text: "Lote", value: "lote" },
      { text: "Fecha", value: "fecha" },
      { text: "Huerta", value: "huerta" },
      { text: "Municipio", value: "municipio" },
      { text: "Chofer", value: "chofer" },
      { text: "Placas", value: "placas" },
      { text: "Importe", value: "importe_transportista", align: "end" },
      { text: "IVA", value: "iva_transportista", align: "end" },
      {
        text: "Retención IVA",
        value: "retencion_iva_transportista",
        align: "end"
      },
      { text: "Total", value: "total_transportista", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    search: undefined
  }),
  computed: {
    ...mapState({
      cortes: state => state.Calculadora.cortes
    }),
    items() {
      return this.cortes.filter(x => x.chofer);
    },
    arrayTransportistas() {
      return [...new Set(this.cortes.map(x => x.transportista))];
    }
  },
  methods:{
    rowClass(item){
      if(item.mixto) return 'blue lighten-5'
    }
  }
};
</script>

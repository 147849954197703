<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4">
          <v-btn small color="info" class="mt-5 ml-3" @click="getAll">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :items-per-page="perPage"
        :headers="headers"
        :items="items"
        class="elevation-1"
        :search.sync="search"
        mobile-breakpoint="0"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="success" @click="getHuerta(item.id)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon color="primary" @click="historialHuerta(item.id)">
            <v-icon>mdi-history</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Huertas",
  data: () => ({
    headers: [
      { text: "Huerta", value: "nombre" },
      { text: "N° Sagarpa", value: "sagarpa" },
      { text: "Global GAP", value: "ggn" },
      { text: "Productor", value: "productor" },
      { text: "Municipio", value: "municipio" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    search: undefined,
    perPage: 15
  }),
  computed: {
    ...mapState({
      items: state => state.Huertas.collection
    })
  },
  mounted() {
    if (!this.items) this.getAll();
  },
  methods: {
    ...mapActions("Huertas", ["getAll", "getHuerta", "historialHuerta"])
  }
};
</script>

import Huertas from "./../views/huertas/index.vue";
import Detalles from "./../views/huertas/form.vue";
import Historial from './../views/huertas/historial.vue';
const routes = [
  {
    path: "/Huertas",
    name: "huertas",
    component: Huertas,
    meta: {
      name: "Catálogo de Huertas"
    }
  },
  {
    path: "/Huertas/editar/:id",
    name: "huertas.edit",
    component: Detalles,
    meta: {
      name: "Editando Huerta"
    }
  },
  {
    path: "/Huertas/historial/:id",
    name: "huertas.history",
    component: Historial,
    meta: {
      name: "Historial Huerta"
    }
  }
];
export default routes;

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text v-if="lotes">
      <v-row>
        <v-col cols="12" md="6">
          <v-card class="ma-1">
            <v-card-title> Lotes {{ lotes.mes }} </v-card-title>
            <v-card-text>
              <v-row>
                <v-col> Huertas con Cortes: {{ lotes.huertas }} </v-col>
                <v-col>Cortes Registrados: {{ lotes.cortes }}</v-col>
                <v-col></v-col>
              </v-row>
              <v-row>
                <v-col> Lotes Orgánicos: {{ lotes.organicos }} </v-col>
                <v-col>
                  Lotes Convencionales: {{ lotes.convencionales }}
                </v-col>
                <v-col> Total Lotes: {{ lotes.total }} </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="ma-1">
            <v-card-title>
              Pagos
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="ma-1">
            <v-card-title>
              Gráfica
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  name: "Dashboard",
  computed: {
    ...mapState({
      lotes: state => state.Dashboard.lotes
    })
  },
  mounted() {
    this.getAll();
  },
  methods: {
    ...mapActions("Dashboard", ["getAll"])
  }
};
</script>

<!-- eslint-disable -->
<template>
  <v-expansion-panel-content>
    <v-row>
      <v-col class="mt-3">
        <v-btn small color="success" class="mt-5" @click="nuevo">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      dense
      mobile-breakpoint="0"
      class="elevation-1 mt-3"
      hide-default-footer
      :headers="headers"
      :items="items"
    >
      <template v-slot:[`item.fecha`]="{ item }">
        {{ item.fecha | date }}
      </template>
      <template v-slot:[`item.cuadrilla`]="{ item }">
        {{ nombreCuadrilla(item.cuadrilla_id) }}
      </template>
      <template v-slot:[`item.tipo_caja`]="{ item }">
        {{ item.tipo_caja | capital }}
      </template>
      <template v-slot:[`item.peso_promedio`]="{ item }">
        {{ (item.peso_neto / item.cajas) | decimal }}
      </template>
      <template v-slot:[`item.peso_neto`]="{ item }">
        {{ item.peso_neto | decimal }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon color="success" @click="editar(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon color="error" @click="eliminar(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-expansion-panel-content>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "TablaCortes",
  data: () => ({
    headers: [
      { text: "Fecha", value: "fecha" },
      { text: "Folio", value: "folio" },
      { text: "Orden de Corte", value: "orden_corte" },
      { text: "Empresa de corte", value: "cuadrilla" },
      { text: "Transportista", value: "transportista" },
      { text: "Chofer", value: "chofer" },
      { text: "Copref / BICO", value: "copref_bico" },
      { text: "Cajas", value: "cajas", align: "end" },
      { text: "Tipo Caja", value: "tipo_caja" },
      { text: "Peso Promedio", value: "peso_promedio", align: "end" },
      { text: "Peso Neto", value: "peso_neto", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ]
  }),
  computed: {
    ...mapState({
      items: state => state.Lotes.resource.cortes,
      cuadrillas: state => state.Lotes.resource.cuadrillas
    })
  },
  methods: {
    nombreCuadrilla(id) {
      if (!id) return "";
      let cuadrilla = this.cuadrillas.find(x => x.id == id);
      return `${cuadrilla.empresa}`;
    },
    nuevo() {
      this.$store.commit("Lotes/SET_CORTE", {});
      this.$store.commit("Lotes/SET_ACTION", "nuevo");
    },
    editar(item) {
      let corte = Object.assign({}, item);
      this.$store.commit("Lotes/SET_CORTE", corte);
      this.$store.commit("Lotes/SET_ACTION", "nuevo");
    },
    eliminar(item) {
      let pregunta = `¿Eliminar el corte folio ${item.folio}?`;
      window
        .swal({
          title: pregunta,
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmed => {
          if (confirmed) {
            this.deleteCorte(item.id);
          }
        });
    },
    ...mapActions("Lotes", ["deleteCorte"])
  }
};
</script>

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="blue lighten-4">
      Cortes
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" xl="4" offset-xl="8">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers"
        class="elevation-1"
        :search.sync="search"
        :items="items"
      >
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
        <template v-slot:[`item.peso_promedio`]="{ item }">
          {{ (item.peso_neto / item.cajas) | decimal }}
        </template>
        <template v-slot:[`item.peso_neto`]="{ item }">
          {{ item.peso_neto | decimal }}
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Cortes",
  data: () => ({
    headers: [
      { text: "Fecha", value: "fecha" },
      { text: "Folio", value: "folio" },
      { text: "Huerta", value: "huerta" },
      { text: "Orden de Corte", value: "orden_corte" },
      { text: "BICO", value: "copref_bico" },
      { text: "Cajas", value: "cajas", align: "end" },
      { text: "Tipo de Caja", value: "tipo_caja" },
      { text: "Peso Neto", value: "peso_neto", align: "end" }
    ],
    perPage: 15,
    search: undefined
  }),
  computed: {
    ...mapState({
      items: state => state.Cuadrillas.cortes
    })
  }
};
</script>

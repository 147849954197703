import router from "../../router";
const url = "Pagos/dashboard";
const initialState = () => ({
  lotes: undefined
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_LOTES(state,payload){
      state.lotes=payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch }) {
      //
      window.axios
        .get(url)
        .then(resp => {
          if (resp.data.message) {
            dispatch("mensaje", resp.data.message, { root: true });
          }
          commit("SET_LOTES",resp.data.lotes);
        })
        .catch(e => dispatch("errores", e, { root: true }));
    }
  }
};

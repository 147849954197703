import router from "../../router";
const url = "/Pagos/huertas";
const initialState = () => ({
  collection: undefined,
  resource: undefined,
  loader: false,
  historial: undefined
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_RESOURCE(state, payload) {
      state.resource = payload;
    },
    SET_LOADER(state, payload) {
      state.loader = payload;
    },
    SET_HISTORIAL(state, payload) {
      state.historial = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch }) {
      commit("SET_COLLECTION", []);
      window.axios
        .get(url)
        .then(resp => {
          commit("SET_COLLECTION", resp.data.collection);
        })
        .catch(e => dispatch("errores", e, { root: true }));
    },
    async getHuerta({ commit, dispatch }, id) {
      commit("SET_LOADER", true);
      commit("SET_RESOURCE", undefined);
      commit("setErrors", [], { root: true });
      await window.axios
        .get(`${url}/${id}`)
        .then(resp => {
          let nombre = `Editando Huerta ${resp.data.nombre}`;
          commit("SET_RESOURCE", resp.data);
          router
            .push({ name: "huertas.edit", params: { id, nombre } })
            .catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async updateHuerta({ commit, dispatch, state }) {
      let huerta = state.resource;
      commit("setErrors", [], { root: true });
      commit("SET_LOADER", true);
      await window.axios
        .put(`${url}/${huerta.id}`, huerta)
        .then(resp => {
          const index = state.collection.findIndex(el => el.id === huerta.id);
          state.collection[index] = resp.data.resource;
          router.push({ name: "huertas" }).catch(() => {});
          dispatch("mensaje", resp.data.message, { root: true });
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async historialHuerta({ commit, dispatch }, id) {
      commit("SET_HISTORIAL", undefined);
      commit("SET_LOADER", true);
      await window.axios
        .get(`${url}/historial/${id}`)
        .then(resp => {
          let nombre = resp.data.title;
          commit("SET_HISTORIAL", resp.data.datos);
          router
            .push({ name: "huertas.history", params: { id, nombre } })
            .catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    }
  }
};

import Cuadrillas from "./../views/cuadrillas/index.vue";
import Form from "./../views/cuadrillas/Form.vue";
import Historial from './../views/cuadrillas/Historial.vue';
const routes = [
  {
    path: "/Cuadrillas",
    name: "cuadrillas",
    component: Cuadrillas,
    meta: {
      name: "Catálogo de Cuadrillas"
    }
  },
  {
    path: "/Cuadrillas/nueva",
    name: "cuadrillas.create",
    component: Form,
    meta: {
      name: "Nueva Cuadrilla"
    }
  },
  {
    path: "/Cuadrillas/editar/:id",
    name: "cuadrillas.edit",
    component: Form,
    meta: {
      name: "Editando Cuadrilla"
    }
  },
  {
    path: "/Cuadrillas/historial/:id",
    name: "cuadrillas.historial",
    component: Historial,
    meta: {
      name: "Historial Cuadrilla"
    }
  }
];
export default routes;

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Pagos
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      aqui la tabla
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: "Pagos"
};
</script>

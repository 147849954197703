<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="blue lighten-4">
      Comisiones
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="mt-3">
        <v-col>
          <v-btn
            small
            color="success"
            @click="dialog = true"
            :disabled="loader"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        hide-default-footer
        class="elevation-1 mt-2"
        mobile-breakpoint="0"
        :loading="loader"
        loading-text="Cargando datos"
      >
      <template v-slot:[`item.kilos`]="{ item }">
          {{ item.kilos | numero }}
        </template>
        <template v-slot:[`item.precio`]="{ item }">
          {{ item.precio | moneda }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ (item.precio * item.kilos) | moneda }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="error"
            v-if="!item.status"
            @click="eliminar(item)"
            :disabled="loader"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-expansion-panel-content>
    <DialogComision v-if="dialog" :dialog="dialog" @cancelar="dialog = false" />
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import DialogComision from "./DialogComision.vue";
export default {
  name: "Comisiones",
  components: { DialogComision },
  data: () => ({
    dialog: false,
    headers: [
      { text: "Comisionista", value: "comisionista.nombre" },
      { text: "Corte", value: "corte.folio" },
      { text: "Observaciones", value: "observaciones" },
      { text: "Kilos", value: "kilos", align: "end" },
      { text: "Precio por kilo", value: "precio", align: "end" },
      { text: "Importe", value: "importe", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ]
  }),
  computed: {
    ...mapState({
      resource: state => state.Lotes.resource,
      loader: state => state.loader,
      errors: state => state.errors
    }),
    items() {
      return this.resource.comisiones.comisiones;
    }
  },
  methods: {
    ...mapMutations(["setLoader", "setErrors"]),
    ...mapActions(["errores", "mensaje"]),
    eliminar(item) {
      let pregunta = `¿ Eliminar la comisión de ${item.nombre}?`;
      window
        .swal({
          title: pregunta,
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmed => {
          if (confirmed) {
            this.setLoader();
            window.axios
              .delete(`/Pagos/lotes/eliminarComision/${item.id}`)
              .then(resp => {
                this.mensaje(resp.data.message);
                this.resource.comisiones.comisiones = resp.data.comisiones;
              })
              .catch(e => this.errores(e))
              .finally(() => this.setLoader(false));
          }
        });
    }
  }
};
</script>

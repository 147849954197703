<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-expansion-panels multiple>
        <Cortes />
        <Pagos />
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="regresar">regresar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import Cortes from "./panelCortes";
import Pagos from "./panelPagos";
export default {
  name: "Historial",
  components: { Cortes, Pagos },
  computed: {
    ...mapState({
      cuadrilla: state => state.Cuadrillas.resource
    })
  },
  mounted() {
    if (!this.cuadrilla.id) this.regresar();
  },
  methods: {
    regresar() {
      this.$router.push({ name: "cuadrillas" }).catch(() => {});
    }
  }
};
</script>

import Vue from "vue";
import VueRouter from "vue-router";

import RoutesUser from "./user";
import RoutesHuertas from "./huertas";
import Cuadrillas from "./cuadrillas";
import Comisionistas from "./comisionistas";
import Lotes from "./lotes";
import Calculadora from "./calculadora";
import Tarifas from "./tarifas";
import SalidasFalse from "./../views/SalidasFalso.vue";
import Pagos from "./pagos";
Vue.use(VueRouter);

const routes = [
  ...RoutesUser,
  ...RoutesHuertas,
  ...Cuadrillas,
  ...Comisionistas,
  ...Lotes,
  ...Calculadora,
  ...Tarifas,
  ...Pagos,
  {
    path: "/Salidas-en-falso",
    name: "salidasFalso",
    component: SalidasFalse,
    meta: {
      name: "Salidas en Falso"
    }
  }
];

const router = new VueRouter({
  routes,
  linkExactActiveClass: "nav-item active",
  mode: "history"
});
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});
export default router;

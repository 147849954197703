<!-- eslint-disable -->
<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Nueva Comisión Lote: {{ lote.lote | folio }}
      </v-card-title>
      <v-form @submit.prevent="sendData" :disabled="loader">
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-combobox
                dense
                small-chips
                outlined
                clearable
                label="Comisionista o Acopiador"
                v-model="comision.nombre"
                :items="comisionistas"
                :error-messages="errors.nombre"
              />
            </v-col>
            <v-col>
              <v-autocomplete
                dense
                outlined
                clearable
                label="Corte"
                v-model="corte"
                :error-messages="errors.corte_id"
                :items="cortes"
                item-value="id"
                item-text="folio"
              />
            </v-col>
            <v-col>
              <v-text-field
                dense
                outlined
                clearable
                label="Kilos a Pagar"
                v-model.number="comision.kilos"
                type="number"
                :error-messages="errors.kilos"
              />
            </v-col>
            <v-col>
              <v-text-field
                dense
                outlined
                clearable
                label="Precio por Kilo"
                v-model.number="comision.precio"
                type="number"
                :error-messages="errors.precio"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                outlined
                clearable
                label="Observaciones"
                v-model="comision.observaciones"
                hide-details="auto"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" :disabled="loader" type="submit">
            guardar
          </v-btn>
          <v-btn color="error" @click="$emit('cancelar')" :disabled="loader">
            cancelar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true
    }
  },
  name: "DialogComision",
  data: () => ({
    comision: {
      nombre: undefined,
      precio: undefined,
      kilos: undefined,
      corte_id: undefined
    },
    corte: undefined
  }),
  computed: {
    ...mapState({
      loader: state => state.loader,
      errors: state => state.errors,
      comisionistas: state => state.Lotes.resource.comisiones.comisionistas,
      lote: state => state.Lotes.resource.lote,
      resource: state => state.Lotes.resource
    }),
    cortes() {
      return this.resource.cortes;
    }
  },
  methods: {
    ...mapMutations(["setLoader", "setErrors"]),
    ...mapActions(["errores", "mensaje"]),
    ...mapActions("Lotes", ["getLote"]),
    sendData() {
      this.setErrors();
      this.setLoader();
      window.axios
        .post(`/Pagos/lotes/comison/${this.$route.params.id}`, this.comision)
        .then(resp => {
          this.mensaje(resp.data.message);
          this.resource.comisiones.comisiones = resp.data.comisiones;
          this.$emit("cancelar");
        })
        .catch(e => this.errores(e))
        .finally(() => this.setLoader(false));
    }
  },
  mounted() {
    this.setErrors();
  },
  watch: {
    corte(val) {
      this.comision.kilos = this.comision.corte_id = undefined;
      let seleccionado = this.cortes.find(x => x.id == val);
      this.comision.kilos = seleccionado.peso_neto;
      this.comision.corte_id = val;
    }
  }
};
</script>

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-form @submit.prevent="getAll">
        <v-row v-if="fechas" class="mt-2">
          <v-col cols="12" md="3">
            <v-text-field
              dense
              outlined
              type="date"
              label="Fecha Inicial"
              v-model="fechas.inicio"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              outlined
              type="date"
              label="Fecha Final"
              v-model="fechas.fin"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-btn color="primary" type="submit">buscar</v-btn>
            <v-btn color="success" @click="limpiar" class="ml-3">limpiar</v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-row>
        <v-col cols="12" xl="4" offset-xl="8">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers"
        class="elevation-1"
        :search.sync="search"
        :items="items"
        :items-per-page="perPage"
      >
        <template v-slot:[`item.lote`]="{ item }">
          {{ item.lote | folio }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
        <template v-slot:[`item.fecha_corte`]="{ item }">
          {{ item.fecha_corte | date }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="primary" @click="getLote(item.id)">
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Lotes",
  data: () => ({
    headers: [
      { text: "Lote", value: "lote" },
      { text: "Tipo de Aguacate", value: "tipo_aguacate" },
      { text: "Fecha Proceso", value: "fecha" },
      { text: "Fecha Corte", value: "fecha_corte" },
      { text: "Huerta", value: "huerta" },
      { text: "Productor", value: "productor" },
      { text: "Cortes Registrados", value: "cortes", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    search: undefined,
    perPage: 15
  }),
  computed: {
    ...mapState({
      items: state => state.Lotes.collection,
      fechas: state => state.Lotes.fechas
    })
  },
  mounted() {
    if (!this.items) this.getAll();
  },
  methods: {
    ...mapActions("Lotes", ["getAll", "getLote"]),
    ...mapMutations("Lotes", ["SET_FECHAS", "SET_COLLECTION"]),
    limpiar() {
      this.SET_FECHAS({ inicio: null, fin: null });
      this.SET_COLLECTION([]);
    }
  }
};
</script>

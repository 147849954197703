import Index from "./../views/pagos/Index.vue";
import Nuevo from "./../views/pagos/Nuevo.vue";
const routes = [
  {
    path: "/Pagos",
    name: "pagos",
    component: Index,
    meta: {
      name: "Control de Pagos"
    }
  },
  {
    path: "/Pagos/nuevo",
    name: "pagos.create",
    component: Nuevo,
    meta: {
      name: "Nuevo Pago"
    }
  }
];
export default routes;

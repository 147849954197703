import router from "../../router";
const url = "/Pagos/tarifas";
const initialState = () => ({
  collection: undefined,
  resource: undefined,
  loader: false
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_RESOURCE(state, payload) {
      state.resource = payload;
    },
    SET_LOADER(state, payload) {
      state.loader = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch }) {
      commit("SET_COLLECTION", []);
      commit("SET_LOADER", true);
      window.axios
        .get(url)
        .then(resp => {
          commit("SET_COLLECTION", resp.data.collection);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    nuevo({ commit }) {
      commit("SET_RESOURCE", {});
      commit("setErrors", [], { root: true });
      router.push({ name: "tarifas.create" }).catch(() => {});
    },
    editar({ commit }, tarifa) {
      commit("SET_RESOURCE", Object.assign({}, tarifa));
      commit("setErrors", [], { root: true });
      let nombre = `Editando tarifa ${tarifa.nombre}`,
        id = tarifa.id;
      router
        .push({ name: "tarifas.edit", params: { nombre, id } })
        .catch(() => {});
    },
    async sendTarifa({ commit, dispatch, state }) {
      commit("setErrors", [], { root: true });
      commit("SET_LOADER", true);
      let tarifa = state.resource;
      if (!tarifa.id) {
        await window.axios
          .post(url, tarifa)
          .then(resp => {
            window.swal(resp.data.message, { icon: "success", timer: 1500 });
            commit("SET_COLLECTION", resp.data.collection);
            router.push({ name: "tarifas" }).catch(() => {});
          })
          .catch(e => dispatch("errores", e, { root: true }))
          .finally(() => commit("SET_LOADER", false));
      } else {
        await window.axios
          .put(`${url}/${tarifa.id}`, tarifa)
          .then(resp => {
            window.swal(resp.data.message, { icon: "success", timer: 1500 });
            commit("SET_COLLECTION", resp.data.collection);
            router.push({ name: "tarifas" }).catch(() => {});
          })
          .catch(e => dispatch("errores", e, { root: true }))
          .finally(() => commit("SET_LOADER", false));
      }
    },
    async deshabilitar({ commit, dispatch }, id) {
      commit("SET_LOADER", true);
      await window.axios
        .delete(`${url}/${id}`)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1500 });
          commit("SET_COLLECTION", resp.data.collection);
          router.push({ name: "tarifas" }).catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async habilitar({ commit, dispatch }, id) {
      commit("SET_LOADER", true);
      await window.axios
        .post(`${url}/restaurar/${id}`)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1500 });
          commit("SET_COLLECTION", resp.data.collection);
          router.push({ name: "tarifas" }).catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    }
  }
};

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="8">
          <v-btn
            small
            color="info"
            class="mt-5 ml-3"
            @click="getAll"
            :disabled="loader"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-btn
            small
            color="success"
            class="mt-5 ml-3"
            :disabled="loader"
            @click="nuevo"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>

      <v-data-table
        dense
        :items-per-page="perPage"
        :headers="headers"
        :items="items"
        :item-class="row_classes"
        class="elevation-1"
        :search.sync="search"
        mobile-breakpoint="0"
      >
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | folio }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
        <template v-slot:[`item.lote`]="{ item }">
          {{ item.lote | folio }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="error" @click="eliminar(item)" :disabled="loader">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Pagos",
  data: () => ({
    headers: [
      { text: "Folio", value: "id" },
      { text: "Fecha", value: "fecha" },
      { text: "Lote", value: "lote" },
      { text: "Tipo de Pago", value: "tipo" },
      { text: "Productor", value: "productor" },
      { text: "Huerta", value: "huerta" },
      { text: "Beneficiario", value: "beneficiario" },
      { text: "Factura", value: "factura" },
      { text: "Referencia", value: "referencia" },
      { text: "Importe", value: "importe", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    search: undefined,
    perPage: 15
  }),
  computed: {
    ...mapState({
      items: state => state.Pagos.collection,
      loader: state => state.Pagos.loader
    })
  },
  mounted() {
    if (!this.items) this.getAll();
  },
  methods: {
    ...mapActions("Pagos", ["getAll", "deletePago"]),

    nuevo() {
      this.$router.push({ name: "pagos.create" }).catch(() => {});
    },
    eliminar(item) {
      let pregunta = `¿Eliminar el pago ${item.folio}?`;
      window
        .swal({
          title: pregunta,
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmed => {
          if (confirmed) {
            this.deletePago(item.id);
          }
        });
    }
  }
};
</script>

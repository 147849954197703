import router from "../../router";
const url = "/Pagos/lotes";
const initialState = () => ({
  collection: undefined,
  resource: undefined,
  loader: false,
  fechas: {
    inicio: undefined,
    fin: undefined
  },
  action: undefined,
  corte: {}
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_RESOURCE(state, payload) {
      state.resource = payload;
    },
    SET_LOADER(state, payload) {
      state.loader = payload;
    },
    SET_FECHAS(state, payload) {
      state.fechas = payload;
    },
    SET_FIN(state, payload) {
      state.fin = payload;
    },
    SET_ACTION(state, payload) {
      state.action = payload;
    },
    SET_CORTE(state, payload) {
      state.corte = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch, state }) {
      commit("SET_COLLECTION", []);
      let inicio = state.fechas.inicio ? state.fechas.inicio : null,
        fin = state.fechas.fin ? state.fechas.fin : null;
      window.axios
        .get(url, {
          params: {
            inicio,
            fin
          }
        })
        .then(resp => {
          commit("SET_COLLECTION", resp.data.collection);
          commit("SET_FECHAS", resp.data.fechas);
        })
        .catch(e => dispatch("errores", e, { root: true }));
    },
    async getLote({ commit, dispatch }, id) {
      commit("SET_LOADER", true);
      await window.axios
        .get(`${url}/${id}`)
        .then(resp => {
          let nombre = resp.data.title;
          commit("SET_RESOURCE", resp.data.resource);
          router
            .push({ name: "lotes.details", params: { id, nombre } })
            .catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async uploadFile({ commit, dispatch, state }, data) {
      commit("SET_LOADER", true);
      window.axios
        .post(`${url}/${state.resource.lote.id}`, data)
        .then(resp => {
          dispatch("mensaje", resp.data.message, { root: true });
          state.resource.archivos = resp.data.archivos;
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async deleteFile({ commit, dispatch, state }, id) {
      commit("SET_LOADER", true);
      window.axios
        .post(`${url}/deleteFile/${id}`)
        .then(resp => {
          dispatch("mensaje", resp.data.message, { root: true });
          state.resource.archivos = resp.data.archivos;
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async storeCorte({ commit, dispatch, state }, corte) {
      commit("SET_LOADER", true);
      commit("setErrors", [], { root: true });
      if (!corte.id) {
        await window.axios
          .post(`${url}/storeCorte/${state.resource.lote.id}`, corte)
          .then(resp => {
            window.swal(resp.data.message, { icon: "success", timer: 1500 });
            commit("SET_RESOURCE", resp.data.resource);
            dispatch("getAll");
            commit("SET_ACTION", undefined);
          })
          .catch(e => dispatch("errores", e, { root: true }))
          .finally(() => commit("SET_LOADER", false));
      } else {
        //updateCorte/{corteLote}
        await window.axios
          .post(`${url}/updateCorte/${corte.id}`, corte)
          .then(resp => {
            window.swal(resp.data.message, { icon: "success", timer: 1500 });
            commit("SET_RESOURCE", resp.data.resource);
            dispatch("getAll");
            commit("SET_ACTION", undefined);
          })
          .catch(e => dispatch("errores", e, { root: true }))
          .finally(() => commit("SET_LOADER", false));
      }
    },
    async deleteCorte({ commit, dispatch }, id) {
      commit("SET_LOADER", true);
      await window.axios
        .delete(`${url}/updateCorte/${id}`)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1500 });
          commit("SET_RESOURCE", resp.data.resource);
          dispatch("getAll");
          commit("SET_ACTION", undefined);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async updateLote({ commit, dispatch, state }, data) {
      commit("SET_LOADER", true);
      let id = state.resource.lote.id;
      await window.axios
        .put(`${url}/${id}`, data)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1500 });
          commit("SET_RESOURCE", resp.data.resource);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    }
  }
};

import Lotes from "./../views/lotes/index.vue";
import Detalles from './../views/lotes/detalles.vue';
const routes = [
  {
    path: "/Lotes",
    name: "lotes",
    component: Lotes,
    meta: {
      name: "Control de Lotes"
    }
  },
  {
    path: "/Lotes/detalles/:id",
    name: "lotes.details",
    component: Detalles,
    meta: {
      name: "Detalle del lote"
    }
  }
];
export default routes;

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loteActual.id)?_c('PagosProductor'):_c('div',{staticClass:"mt-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-select',{attrs:{"dense":"","outlined":"","small-chips":"","clearable":"","items":_vm.productores,"label":"Productor"},model:{value:(_vm.productor),callback:function ($$v) {_vm.productor=$$v},expression:"productor"}})],1)],1),(_vm.items.length)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","search":_vm.search,"headers":_vm.headers,"items":_vm.items,"mobile-breakpoint":"0"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.lote",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("folio")(item.lote))+" ")]}},{key:"item.orden_corte",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("folio")(item.orden_corte))+" ")]}},{key:"item.kilos_procesados",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("decimal")(item.kilos_procesados))+" ")]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fecha))+" ")]}},{key:"item.precio_kilo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.precio_kilo))+" ")]}},{key:"item.importe_productor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe_productor))+" ")]}},{key:"item.pago_real_productor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.pago_real_productor))+" ")]}},{key:"item.pagos_productor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.pagos_productor))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"success","disabled":_vm.loader},on:{"click":function($event){return _vm.editar(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),(
          item.pago_real_productor &&
            item.pago_real_productor !== item.pagos_productor
        )?_c('v-btn',{attrs:{"icon":"","color":"primary","disabled":_vm.loader},on:{"click":function($event){return _vm.pagar(item)}}},[_c('v-icon',[_vm._v("mdi-currency-usd-circle")])],1):_vm._e()]}}],null,true)}):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"650px"},model:{value:(_vm.editando),callback:function ($$v) {_vm.editando=$$v},expression:"editando"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.titulo(_vm.lote))+" Importe Real del lote "+_vm._s(_vm.lote.lote)+" ")]),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.enviar($event)}}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","type":"number","step":"0.01","label":"Iporte Real","error-messages":_vm.errors.importe},model:{value:(_vm.importeReal),callback:function ($$v) {_vm.importeReal=_vm._n($$v)},expression:"importeReal"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error","disabled":_vm.loader},on:{"click":_vm.cancelar}},[_vm._v(" cancelar ")]),(_vm.importeReal)?_c('v-btn',{attrs:{"color":"success","type":"submit","disabled":_vm.loader}},[_vm._v(" Guardar ")]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4">
          <v-btn
            small
            color="info"
            class="mt-5 ml-3"
            :disabled="loader"
            @click="getAll"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-btn
            small
            color="success"
            class="mt-5 ml-3"
            :disabled="loader"
            @click="nuevo"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        class="elevation-1"
        :search.sync="search"
        :headers="headers"
        :items="items"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | folio }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="success" @click="editar(item)" :disabled="loader">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon color="error" @click="eliminar(item)" :disabled="loader">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="dialog" persistent max-width="650px">
      <v-card>
        <v-card-title class="headline">
          {{ titulo }}
        </v-card-title>
        <v-form @submit.prevent="guardar">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  dense
                  outlined
                  label="Fecha"
                  v-model="salida.fecha"
                  type="date"
                  :error-messages="errors.fecha"
                  :max="today"
                />
              </v-col>
              <v-col cols="12" md="8">
                <v-autocomplete
                  dense
                  outlined
                  small-chips
                  clearable
                  :items="huertas"
                  v-model="salida.huerta_id"
                  item-text="text"
                  label="Huerta"
                  :error-messages="errors.huerta_id"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  dense
                  outlined
                  small-chips
                  clearable
                  :items="cuadrillas"
                  v-model="salida.cuadrilla_id"
                  item-text="text"
                  label="Cuadrilla"
                  :error-messages="errors.cuadrilla_id"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-combobox
                  dense
                  outlined
                  small-chips
                  clearable
                  :items="transportistas"
                  v-model="salida.transportista"
                  label="Transportista"
                  :error-messages="errors.transportista"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  outlined
                  label="Motivo"
                  v-model="salida.motivo"
                  :error-messages="errors.motivo"
                />
              </v-col>
              <v-col>
                <v-textarea
                  outlined
                  label="Observaciones"
                  v-model="salida.observaciones"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" @click="cancelar" :disabled="loader">
              cancelar
            </v-btn>
            <v-btn color="success" type="submit" :disabled="loader">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SalidasFalso",
  data: () => ({
    headers: [
      { text: "Folio", value: "id" },
      { text: "Fecha", value: "fecha" },
      { text: "Transportista", value: "transportista" },
      { text: "Cuadrilla", value: "cuadrilla" },
      { text: "Huerta", value: "huerta" },
      { text: "Municipio", value: "municipio" },
      { text: "Motivo", value: "motivo" },
      { text: "Observaciones", value: "observaciones" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    search: undefined,
    loader: false,
    dialog: false,
    salida: {},
    items: [],
    huertas: [],
    transportistas: [],
    cuadrillas: []
  }),
  computed: {
    titulo() {
      return this.salida.id
        ? "Editando Salida en Falso"
        : "Nueva Salida en Falso";
    },
    ...mapState({
      errors: state => state.errors
    }),
    today() {
      return new Date().toISOString().split("T")[0];
    }
  },
  mounted() {
    this.getAll();
  },
  methods: {
    nuevo() {
      this.dialog = true;
      this.salida = {};
      this.$store.commit("setErrors", []);
    },
    editar(item) {
      this.dialog = true;
      this.salida = Object.assign({}, item);
      this.$store.commit("setErrors", []);
    },
    cancelar() {
      this.dialog = false;
    },
    getAll() {
      this.loader = true;
      window.axios
        .get("/Pagos/salidasFalso")
        .then(resp => {
          this.huertas = resp.data.huertas;
          this.items = resp.data.items;
          this.transportistas = resp.data.transportistas;
          this.cuadrillas = resp.data.cuadrillas;
        })
        .catch(e => this.$store.dispatch("errores", e))
        .finally(() => (this.loader = false));
    },
    guardar() {
      let url = "/Pagos/salidasFalso";
      if (this.salida.id) url = `${url}/${this.salida.id}`;
      this.$store.commit("setErrors", []);
      this.loader = true;
      window.axios
        .post(url, this.salida)
        .then(resp => {
          this.items = resp.data.items;
          window.swal(resp.data.message, { icon: "success", timer: 1500 });
          this.dialog = false;
        })
        .catch(e => this.$store.dispatch("errores", e))
        .finally(() => (this.loader = false));
    },
    eliminar(item) {
      let pregunta = `¿Eliminar la salida en falso ${item.folio}?`;
      window
        .swal({
          title: pregunta,
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmed => {
          if (confirmed) {
            let url = `/Pagos/salidasFalso/${item.id}`;
            this.loader = true;
            window.axios
              .delete(url)
              .then(resp => {
                this.items = resp.data.items;
                window.swal(resp.data.message, {
                  icon: "success",
                  timer: 1500
                });
              })
              .catch(e => this.$store.dispatch("errores", e))
              .finally(() => (this.loader = false));
          }
        });
    }
  }
};
</script>
